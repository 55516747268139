$i: 5;
@for $value from 0 through 12 {
  .u-m#{ $value * $i } {
    margin: $i * $value + px!important;
  }
  .u-mtb#{ $value * $i } {
    margin-top: $i * $value + px!important;
    margin-bottom: $i * $value + px!important;
  }
  .u-mlr#{ $value * $i } {
    margin-left: $i * $value + px!important;
    margin-right: $i * $value + px!important;
  }
  .u-mt#{ $value * $i } {
    margin-top: $i * $value  + px!important;
  }
  .u-mb#{ $value * $i } {
    margin-bottom: $i * $value + px!important;
  }
  .u-ml#{ $value * $i } {
    margin-left: $i * $value + px!important;
  }
  .u-mr#{ $value * $i } {
    margin-right: $i * $value + px!important;
  }
  .u-p#{ $value * $i } {
    padding: $i * $value + px!important;
  }
  .u-pt#{ $value * $i } {
    padding-top: $i * $value + px!important;
  }
  .u-pb#{ $value * $i } {
    padding-bottom: $i * $value + px!important;
  }
  .u-pl#{ $value * $i } {
    padding-left: $i * $value + px!important;
  }
  .u-pr#{ $value * $i } {
    padding-right: $i * $value + px!important;
  }
}

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

$j: 10;
@for $value from 1 through 9 {
  .u-mb {
    &#{ $value * $j }_half {
      margin-bottom: $value * $j / 2 + px!important;
      @media all and (min-width:600px) {
        margin-bottom: $value * $j + px!important;
      }
    }
  }
}

$k: 15;
@for $value from 1 through 12 {
  .u-mb {
    &#{ $value * $k }_23rd {
      margin-bottom: $value * $k * 2 / 3 + px!important;
      @media all and (min-width:600px) {
        margin-bottom: $value * $k + px!important;
      }
    }
  }
}

$l: 10;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $l }_level {
      margin-bottom: $value * $l / 3 + px!important;
      @media all and (min-width:520px) {
        margin-bottom: $value * $l / 2 + px!important;
      }
      @media all and (min-width:800px) {
        margin-bottom: $value * $l + px!important;
      }
    }
  }
}

$j: 10;
@for $value from 1 through 9 {
  .u-mb-minus {
    &#{ $value * $j }_half {
      margin-bottom: - $value * $j / 2 + px!important;
      @media all and (min-width:600px) {
        margin-bottom: - $value * $j + px!important;
      }
    }
  }
}