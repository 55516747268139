.p1-logo {
  width: calc(100% - #{$drawer-space}*2 );
  display: block;
  position: relative;
  z-index: 1200;
  @media (min-width:800px) {
    width: 100%;
  }
  &_inner {
    width: 90%;
    margin: 0 auto;
    position: relative;
    @media (min-width:1120px) {
      width: 1000px;
    }
  }
  &_item {
    display: block;
    &.-olivier {
      width: 100px;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      @media (min-width:800px) {
        display: block;
      }
    }
    &.-caremane {
      width: 436px;
      margin: 28px auto 0;
      display: none;
      @media (min-width:800px) {
        display: block;
        transform: translate(0.8125vw, 0);
      }
      @media (min-width:900px) {
        transform: translate(1.625vw, 0);
      }
      @media (min-width:1000px) {
        transform: translate(3.25vw, 0);
      }
      @media (min-width:1080px) {
        transform: translate(6.5vw, 0);
      }
      @media (min-width:1180px) {
        transform: translate(52px, 0);
      }
    }
    &.-comp {
      width: 140px;
      position: fixed;
      top: 10px;
      left: 5%;
      @media (min-width:800px) {
        width: 176px;
        top: 8px;
      }
    }
  }
  &_img {
    display: block;
    
    &.-full {
      display: none;
      @media (min-width:1000px) {
        display: block;
      }
    }
    
    &.-slim {
      display: block;
      @media (min-width:1000px) {
        display: none;
      }
    }
  }
}