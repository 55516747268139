.p0-decoration {
  display: block;
  & img { display: block; }

  &.-bee01 {
    width: 54px;
    display: block;
    position: absolute;
    left: inherit;
    right: 6vw;
    bottom: inherit;
    top: 80px;
    transform: scale(-1, 1);
    @media (min-width:500px) {
      left: 47vw;
      right: inherit;
    }
    @media (min-width:700px) {
      width: 60px;
      top: inherit;
      bottom: 50px;
      right: 20%;
      left: inherit;
    }
  }

  &.-bee02 {
    width: 38px;
    display: block;
    position: absolute;
    left: 5vw;
    top: inherit;
    bottom: 90px;
    @media (min-width:400px) {
      left: 5vw;
      top: inherit;
      bottom: 35px;
    }
    @media (min-width:600px) {
      width: 48px;
      left: 9vw;
      top: -5px;
      bottom: inherit;
    }
    @media (min-width:700px) {
      width: 58px;
    }
    @media (min-width:1000px) {
      width: 68px;
    }
  }

  &.-bee03 {
    width: 36px;
    position: absolute;
    top: -24px;
    right: 25%;
    transform: scale(-1, 1);
    @media (min-width:600px) {
      width: 48px;
      top: -24px;
      right: 250px;
    }
    @media (min-width:940px) {
      width: 57px;
      right: 0;
      left: 400px;
      margin: auto;
    }
  }

  &.-bee04 {
    width: 36px;
    position: absolute;
    bottom: 0;
    left: 3%;
    @media (min-width:600px) {
      width: 48px;
      top: 28px;
      left: 3%;
    }
    @media (min-width:800px) {
      width: 57px;
      top: 74px;
      right: 0;
      left: -520px;
      margin: auto;
    }
  }
  &.-bee05 {
    width: 38px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: inherit;
    margin: auto;
    @media (min-width:600px) {
      width: 48px;
      left: 3%;
      right: inherit;
      top: inherit;
      bottom: 5vw;
    }
    @media (min-width:700px) {
      width: 58px;
    }
    @media (min-width:1000px) {
      width: 68px;
    }
    @media (min-width:1120px) {
      left: calc(50% - 500px - 30px );
    }
  }

  &.-bee06 {
    display: block;
    position: absolute;
    top: 50%;
    right: -9vw;
    width: 38px;
    transform: translate(100%, -50%) scale(-1, 1);
    margin: auto;
    @media (min-width:600px) {
      width: 48px;
    }
    @media (min-width:700px) {
      width: 58px;
    }
    @media (min-width:1000px) {
      width: 68px;
      right: -90px;
    }
    @media (min-width:1120px) {
    }
  }

  &.-bee07 {
    display: block;
    position: absolute;
    top: 50%;
    left: -9vw;
    width: 38px;
    transform: translate(-100%, -50%);
    margin: auto;
    @media (min-width:600px) {
      width: 48px;
    }
    @media (min-width:700px) {
      width: 58px;
    }
    @media (min-width:1000px) {
      width: 68px;
      left: -90px;
    }
    @media (min-width:1120px) {
    }
  }

  &.-leaf {
    width: 110px;
    position: absolute;
    left: -5%;
    bottom: -20px;
    @media (min-width:600px) {
      left: -4%;
    }
    @media (min-width:800px) {
      width: 131px;
      left: 4.5%;
    }
    @media (min-width:1120px) {
      left: calc(50% - 1010px / 2);
    }
  }

  &.-flower01 {
    position: absolute;
    left: 0x;
    bottom: 10px;
    transform: translate(-75%, 0);
    width: 134px;
    @media (min-width:600px) {
      bottom: 90px;
    }
    @media (min-width:800px) {
      transform: translate(-80%, 0);
    }
    @media (min-width:1120px) {
      left: -10px;
      width: 161px;
      transform: translate(-80%, 0);
    }
    @media (min-width:1150px) {
      transform: translate(-90%, 0);
    }
    @media (min-width:1200px) {
      transform: translate(-100%, 0);
    }
  }

  &.-flower04 {
    position: absolute;
    right: 0;
    top: 0;
    right: 50%;
    transform: translateX(100%);
    width: 142px;
    @media (min-width:600px) {
      right: calc(50% + 50px - 600px / 2);
      transform: translateX(0);
    }
    @media (min-width:800px) {
      width: 170px;
      right: calc(50% + 100px - 800px / 2);
    }
    @media (min-width:1120px) {
      right: calc(50% + 100px - 1000px / 2);
    }
  }

  &.-flower05 {
    position: absolute;
    left: -5%;
    bottom: 0;
    width: 102px;
    @media (min-width:600px) {
      left: -4%;
    }
    @media (min-width:800px) {
      width: 122px;
      left: 4.5%;
    }
    @media (min-width:1120px) {
      left: calc(50% - 1010px / 2);
    }
  }

  &.-flower06 {
    position: absolute;
    right: -5%;
    bottom: 0;
    width: 68px;
    @media (min-width:600px) {
      right: -4%;
    }
    @media (min-width:800px) {
      width: 82px;
      right: 4.5%;
    }
    @media (min-width:1120px) {
      right: calc(50% - 1000px / 2);
    }
  }

  &.-flower07 {
    position: absolute;
    top: 60px;
    left: -7.1428571428571vw;
    width: 21.6071428571429vw;
//    width: 201px;
    @media (min-width:600px) {
      left: -7.1428571428571vw;
    }
    @media (min-width:800px) {
      width: 242px;
      left: -7.1428571428571vw;
    }
    @media (min-width:1120px) {
      left: -80px;
    }
  }

  &.-ftlogo {
    width: 102px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width:480px) {
      left: auto;
      right: 0;
      transform: translateX(0);
    }
    @media (min-width:800px) {
      width: 122px;
      bottom: 60px;
    }
  }
}