body {
  color: color-txt01(1);
  background: color-base01(1);
  width: 100%;
  font-size: 1.5rem;
  line-height: 2;
  @include font-ntsan(r);
  @media (min-width:600px) {
    font-size: 1.7rem;
  }
  @media (min-width:800px) {
    line-height: 2.4;
  }
}

a {
  color:color-txt01(0.8);
  text-decoration:none;
}

::selection,::-moz-selection {
  background:color-effect02(1);
}