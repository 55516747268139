.p0-meritbox {
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 5;
  max-width: 600px;
  @media (min-width:600px) {
    width: 66.6666666666667vw;  /* 600px / 900px */
  }
  @media (min-width:900px) {
    width: 600px;
  }

  &_item {
    position: relative;
    z-index: 6;
    width: 47%;  /* 280px / 600px */
    padding: 0 3%;
    box-sizing: border-box;
    @media (min-width:600px) {
      width: 31.1111111111111vw;  /* 280px / 900px */
      padding: 0 2.2222222222222vw; /* 20px / 900px */
    }
    @media (min-width:900px) {
      width: 280px;
      padding: 0 20px;
    }
    
    &.-merit3 {
      margin-top: -3.3333333333333vw; /* 30px / 600px */
      margin-bottom: 10px;
      @media (min-width:600px) {
        margin-top: -3.3333333333333vw; /* 30px / 900px */
        margin-bottom: 0;
      }
      @media (min-width:900px) {
        margin-top: -30px;
      }
    }
  }
  
  &_joinus {
    position: relative;
    z-index: 5;
    width: 70%;
    max-width: 300px;
    margin-top: -14vw;
    @media (min-width:600px) {
      position: absolute;
      top: 0;
      right: calc(2.2222222222222vw * 4);
      z-index: 5;
      transform: translate(100%, -32%);
      width: 63.8888888888889vw; /* 575px / 900px */
      max-width: 575px;
      margin-top: 0;
    }
    @media (min-width:900px) {
      right: 80px;
      width: 575px;
    }
  }
}