.p3-box {
  &.-frontbox01 {
    position: relative;
    
    @media (min-width:800px) {
      
    }
    
    & .p3-img {
      position: relative;
    }
    
    & .p3-headline {
      display: block;
      position: absolute;
      top: 38%;
      right: 0;
      transform: translateY(-50%);
      text-align: justify;
    }
  }
}