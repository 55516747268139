.p1-gnav {
  width: 100%;
  background: color-base02(1);
  position: fixed;
  top:0;
  left: 0;
  @media all and (min-width:800px) {
    background: transparent;
  }

  &::before {
    content: '';
    background: color-base01(1);
    display: block;
    width: 100%;
    height: $drawer-space;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    @media all and (min-width:800px) {
      display: none;
      background: color-base01(.85);
    }
  }

  &_content {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: -100vh;
    z-index: 99;
    background: color-base02(.95);
    transition: .5s;
    @media all and (min-width:800px) {
      height: inherit;
      position: static;
      background: color-base01(.85);
    }
  }

  &_list {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: calc(10px + #{$drawer-space}) 0 0 0;
    @media all and (min-width:800px) {
      flex-direction: row;
      justify-content: center;
      padding: 0;
    }
  }

  &_item {
    display: block;
    transition: all linear;
    @media all and (min-width:800px) {
      
    }
  }

  &_itemInner {
    display: block;
    color: color-txt01(1);
    font-size: 2rem;
    text-align: center;
    transition: all linear;
    line-height: 1;
    padding: 15px 0;
    @include font-ntsan("m");
    @media all and (min-width:800px) {
      font-size: 2vw;
      padding: 23px 1.2vw;
      line-height: 1;
    }
    @media all and (min-width:940px) {
      font-size: 2rem;
      padding: 23px 22px;
    }
    &:hover, &:focus {
      color: color-effect03(1);
    }
    &.is-current {
      color: color-effect02(1);
    }
  }

  &.is-head {
    position: relative;
    & .p1-gnav_content {
      background: color-base01(0);
    }
  }

  &_list.-contact {
    z-index: 5000;
    @media (min-width:800px) {
      width: 180px;
      flex-direction: column;
      position: fixed;
      top: 25px;
      right: 3%;
    }
    @media (min-width:1000px) {
      width: 200px;
    }
  }

  &_list.-contact &_item {
    margin: 5px 0;
    @extend %hover;
    @media (min-width:800px) {
      margin: 5px 0 5px 5px;
    }
    & .p1-gnav_itemInner {
      padding: 14px 3px 16px;
      color: color-wh(1);
      border-radius: 25px;
    }
    &.-anything .p1-gnav_itemInner {
      background: color-effect02(1);
    }
    &.-entry .p1-gnav_itemInner {
      background: color-base09(1);
    }
  }

  &_list.-contact.is-scroll {
    width: 290px;
    flex-direction: row;
    justify-content: flex-end;
    top: 5px;
    & .p1-gnav_item {
      width: 16vw;
      @media (min-width:940px) {
        width: 140px;
      }
    }
    & .p1-gnav_itemInner {
      font-size: 1.5rem;
      @media (min-width:800px) {
        font-size: 1.8vw;
      }
      @media (min-width:940px) {
        font-size: 1.5rem;
      }
    }
  }

}