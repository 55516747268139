.c-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;

  &_item {
    &.-box3 {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      @media all and (min-width:520px) {
        width: 47.5%;
        margin-bottom: 30px;
      }
      @media all and (min-width:740px) {
        width: 31%;
        margin-bottom: 0;
      }
      @media all and (min-width:920px) {
        width: 29.3%;
      }
    }
    
    &.-dummy {
      display: none;
      opacity: 0;
      height: 1px;
 
      @media all and (min-width:520px) {
        display: block;
        width: 1%;
      }
    }
  }

  &.-odd {
    @media all and (min-width:520px) and (max-width:739px) {
      justify-content: center;
    }
    & .c-flex_item {
      &:nth-child(odd) {
        @media all and (min-width:520px) and (max-width:739px) {
          margin-right: 5%;
        }
      }
      &:last-child {
        @media all and (min-width:520px) and (max-width:739px) {
          margin: 0;
        }
      }
    }
  }

/*
  &.-post {
    & .c-flex_item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 20px;
      }
      @media all and (min-width:740px) {
        margin-bottom: 40px;
      }
    }
  }
*/

}

.c-flex02 {
  display: flex;
  flex-flow: column wrap;
  @media all and (min-width:600px) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  &_item {
    &.-item01 {
      width: 60vw;
      max-width: 500px;
      margin: 0 auto;
      order: 2;
      display: none;
      @media (min-width:600px) {
        width: 40%;
        padding: 0 5% 0 0;
        order: 1;
        display: block;
      }
      @media (min-width:800px) {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
        padding: 0 60px 0 0;
      }
    }
    &.-item02 {
      width: 100%;
      order: 1;
      margin-bottom: 6vw;
      @media (min-width:600px) {
        width: 60%;
        order: 2;
        margin-bottom: 0;
      }
      @media (min-width:800px) {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
      }
    }
  }
}