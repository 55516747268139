.p0-hero {
  padding-bottom: 0;
  @media all and (min-width:800px) {
    padding-bottom: 20px;
  }
  &.-front {
    @media all and (min-width:800px) {
      margin-top: -15px;
    }

    & .p0-hero_content {
      @media all and (min-width:600px) {
        width: calc(95% + 20px);
      }
      @media all and (min-width:1120px) {
        width: calc( 1000px + 20px + ( (100% - 1000px ) / 2 ) );
      }
      @media all and (min-width:1320px) {
        width: calc( 1000px + 20px + ( (100% - 1000px ) / 2 ) );
        margin: 0 0 0 auto;
      }
      @media all and (min-width:1800px) {
        width: 1400px;
        transform: translateX(-20px);
      }
     @media all and (min-width:1820px) {
        width: 1400px;
        margin: 0 auto 0 calc( ( ( 100% - 1000px ) / 2 ) - 20px );
        transform: translateX(0);
      }
    }
  }
  
  &.-sub {
    background-image: url(../images/common/flower02.png), url(../images/common/flower03.png);
    background-size: 9.5833333333333vw, 13.4166666666667vw; /* 115px / 1200px, 161px / 1200px */
//    background-position: left calc(100% - 20px), right bottom;
    background-position: calc(6px - 4.79166666666665vw) calc(100% - 20px), calc(100% + 3px + 6.70833333333335vw) bottom;
    background-repeat: no-repeat, no-repeat;
    @media (min-width:600px) {
      background-position: calc(12px - 4.79166666666665vw) calc(100% - 20px), calc(100% + 6px + 6.70833333333335vw) bottom;
    }
    @media (min-width:800px) {
      background-position: calc(12px - 4.79166666666665vw) calc(100% - 20px), calc(100% + 6px + 6.70833333333335vw) bottom;
    }
    @media (min-width:1120px) {
      background-position: calc(50% - 500px - 25px - 57px) calc(100% - 20px), calc(50% + 500px + 12px + 80px) bottom;
    }
    @media (min-width:1200px) {
      background-size: 115px, 161px;
      background-position: calc(50% - 500px - 50px - 57px) calc(100% - 20px), calc(50% + 500px + 25px + 80px) bottom;
      /* calc(50%[中心] - 500px[KV幅/2] - 50px[KVから花までの余白] - 57px[花画像幅/2]) calc(50%[中心] + 500px[KV幅/2] + 25px[KVから花までの余白] + 80px[花画像/2]) */
    }
  }

  &_slider {
    position: relative;
    padding: 0 0 20px ;
    @media (min-width:600px) {
      padding: 30px 0 0 20px;
    }
    &_item {
      position: relative;
      & img { display: block; }
      & .-txt {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        @media (min-width:600px) {
          display: block;
        }
      }
    }
    &_dots {
      position: absolute;
      left: 3%;
      bottom: 33px;
      @media (min-width:600px) {
        left: 0;
      }
      & ul {
        width: 10px;
      }
      & li {
        display: block;
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
        border-radius: 100px;
        background: color-wh(.8);
        &:last-child {
          margin-bottom: 0;
        }
        @media (min-width:600px) {}
      }
      & button {
        width: 10px;
        height: 10px;
        background: color-effect01(.4);
        text-indent: -9999px;
        border-radius: 100px;
      }
      & .slick-active button {
        background: color-effect01(1);
      }
    }
  }

  &.-sub {
    .p0-hero_img { display: block; }
  }

  &_wrap {
    display: flex;
    align-items: center;
    padding: 15px 0 0 0;
    @media (min-width:600px) {
      padding: 15px 0 0 0;
    }
  }

  &_headline {
    & img { display: block; }
    &.-front {
      width: 90%;
      max-width: 300px;
      display: block;
      margin: auto;
      @media (min-width:600px) {
        position: absolute;
        bottom: 50px;
        left: 65px;
      }
      
      & .catchcopy {
        &.-pc {
          display: none;
          @media (min-width:600px) {
            display: block;
          }
        }
        &.-sp {
          display: block;
          @media (min-width:600px) {
            display: none;
          }
        }
      }

    }
    &.-page {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      line-height: 1;
    }
  }

}