@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Noto+Sans+JP:wght@400;500;700&family=Shippori+Mincho:wght@600&display=swap");
html {
  font-size: 62.5%; }

#defs {
  display: none; }

img {
  width: 100%; }

ul li {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

svg {
  display: block; }

@font-face {
  font-family: 'genju-m';
  src: url("/assets/font/genju-m.eot");
  /* IE9 Compat Modes */
  src: url("/assets/font/genju-m.eot?#iefix") format("embedded-opentype"), url("/assets/font/genju-m.woff") format("woff"), url("/assets/font/genju-m.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'genju-b';
  src: url("/assets/font/genju-b.eot");
  /* IE9 Compat Modes */
  src: url("/assets/font/genju-b.eot?#iefix") format("embedded-opentype"), url("/assets/font/genju-b.woff") format("woff"), url("/assets/font/genju-b.ttf") format("truetype");
  /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal; }

/*
@mixin font-ntsel($status) {
  font-family: 'Noto Serif JP', serif;
  font-style: normal;
  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'b' {
    font-weight: 700;
  }
}
*/
.externalIcon {
  padding: 0 5px;
  fill: #fb5785;
  width: 14px;
  height: 14px; }

.align-l {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-l {
      text-align: left !important; } }

.align-r {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-r {
      text-align: right !important; } }

.align-c {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-c {
      text-align: center !important; } }

.br {
  display: none; }
  @media all and (min-width: 800px) {
    .br {
      display: inline-block; } }

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/
.hover, .p0-btn1_inner, .p0-btn2_inner, .p0-btn3_inner, .p0-btn4_inner, .p1-gnav_list.-contact .p1-gnav_item {
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all 0.3s ease-in-out; }
  .hover:hover, .hover:focus, .p0-btn1_inner:hover, .p0-btn2_inner:hover, .p0-btn3_inner:hover, .p0-btn4_inner:hover, .p1-gnav_list.-contact .p1-gnav_item:hover, .p0-btn1_inner:focus, .p0-btn2_inner:focus, .p0-btn3_inner:focus, .p0-btn4_inner:focus, .p1-gnav_list.-contact .p1-gnav_item:focus {
    filter: alpha(opacity=70);
    opacity: 0.7; }

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block; }
  @media (min-width: 800px) {
    .anchor {
      top: -90px; } }

body {
  color: #665c54;
  background: white;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400; }
  @media (min-width: 600px) {
    body {
      font-size: 1.7rem; } }
  @media (min-width: 800px) {
    body {
      line-height: 2.4; } }

a {
  color: rgba(102, 92, 84, 0.8);
  text-decoration: none; }

::selection, ::-moz-selection {
  background: #fc922a; }

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; }
  @media all and (min-width: 800px) {
    .l-header {
      position: relative; } }

.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
  overflow: hidden; }
  @media all and (min-width: 600px) {
    .l-section {
      padding: 60px 0; } }
  .l-section.-pad0 {
    padding: 0; }
  .l-section.-largeSpace {
    padding: 40px 0; }
    @media all and (min-width: 600px) {
      .l-section.-largeSpace {
        padding: 80px 0; } }
    .l-section.-largeSpace.-pb0 {
      padding: 40px 0 0; }
      @media all and (min-width: 600px) {
        .l-section.-largeSpace.-pb0 {
          padding: 80px 0 0; } }
  .l-section.-gray {
    background: #f1f3f1; }
  .l-section_content {
    width: 90%;
    margin: 0 auto;
    position: relative;
    overflow: hidden; }
    @media all and (min-width: 1120px) {
      .l-section_content {
        width: 1000px; } }
    @media all and (min-width: 1180px) {
      .l-section_content.-wide {
        width: 1120px; } }
    .l-section_content.-fullright {
      width: 95%;
      margin: 0 0 0 auto;
      position: relative;
      overflow: hidden; }
      @media all and (min-width: 1320px) {
        .l-section_content.-fullright {
          width: 1160px;
          margin: 0 auto; } }
    .l-section_content.-visible {
      overflow: visible; }
  .l-section.-last {
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 60px; }
    @media all and (min-width: 600px) {
      .l-section.-last {
        padding-top: 120px;
        padding-bottom: 120px; } }

.l-footer {
  background: #f1f3f1 url(../images/common/logo_bg.png) right bottom/394px auto no-repeat;
  padding: 20px 0 0 0; }
  @media (min-width: 600px) {
    .l-footer {
      padding: 40px 0 0 0; } }

.c-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-start;
  /*
  &.-post {
    & .c-flex_item {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 20px;
      }
      @media all and (min-width:740px) {
        margin-bottom: 40px;
      }
    }
  }
*/ }
  .c-flex_item.-box3 {
    width: 100%;
    margin-bottom: 20px; }
    .c-flex_item.-box3:last-child {
      margin-bottom: 0; }
    @media all and (min-width: 520px) {
      .c-flex_item.-box3 {
        width: 47.5%;
        margin-bottom: 30px; } }
    @media all and (min-width: 740px) {
      .c-flex_item.-box3 {
        width: 31%;
        margin-bottom: 0; } }
    @media all and (min-width: 920px) {
      .c-flex_item.-box3 {
        width: 29.3%; } }
  .c-flex_item.-dummy {
    display: none;
    opacity: 0;
    height: 1px; }
    @media all and (min-width: 520px) {
      .c-flex_item.-dummy {
        display: block;
        width: 1%; } }
  @media all and (min-width: 520px) and (max-width: 739px) {
    .c-flex.-odd {
      justify-content: center; } }
  @media all and (min-width: 520px) and (max-width: 739px) {
    .c-flex.-odd .c-flex_item:nth-child(odd) {
      margin-right: 5%; } }
  @media all and (min-width: 520px) and (max-width: 739px) {
    .c-flex.-odd .c-flex_item:last-child {
      margin: 0; } }

.c-flex02 {
  display: flex;
  flex-flow: column wrap; }
  @media all and (min-width: 600px) {
    .c-flex02 {
      flex-flow: row nowrap;
      justify-content: space-between; } }
  .c-flex02_item.-item01 {
    width: 60vw;
    max-width: 500px;
    margin: 0 auto;
    order: 2;
    display: none; }
    @media (min-width: 600px) {
      .c-flex02_item.-item01 {
        width: 40%;
        padding: 0 5% 0 0;
        order: 1;
        display: block; } }
    @media (min-width: 800px) {
      .c-flex02_item.-item01 {
        width: 50%; } }
    @media (min-width: 900px) {
      .c-flex02_item.-item01 {
        width: 500px;
        padding: 0 60px 0 0; } }
  .c-flex02_item.-item02 {
    width: 100%;
    order: 1;
    margin-bottom: 6vw; }
    @media (min-width: 600px) {
      .c-flex02_item.-item02 {
        width: 60%;
        order: 2;
        margin-bottom: 0; } }
    @media (min-width: 800px) {
      .c-flex02_item.-item02 {
        width: 50%; } }
    @media (min-width: 900px) {
      .c-flex02_item.-item02 {
        width: 500px; } }

.p0-btn1 {
  width: 100%;
  max-width: 552px;
  clear: both;
  text-align: center; }
  @media (min-width: 600px) {
    .p0-btn1 {
      width: 552px; } }
  .p0-btn1_inner {
    width: 100%;
    padding: 15px 0;
    display: block;
    color: white;
    line-height: 1.2;
    cursor: pointer;
    border-radius: 42px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500; }
    @media (min-width: 600px) {
      .p0-btn1_inner {
        padding: 20px 0; } }
  .p0-btn1_inner {
    background: #fc922a;
    font-size: 2.56rem; }
    @media all and (min-width: 600px) {
      .p0-btn1_inner {
        font-size: 3.2rem; } }
  @media (max-width: 600px) {
    .p0-btn1 {
      max-width: 280px; } }
  @media (min-width: 600px) and (max-width: 660px) {
    .p0-btn1 {
      width: 100%; } }
  @media (min-width: 600px) and (max-width: 956px) {
    .p0-btn1.-type01 {
      width: 100%; } }

.p0-btn2 {
  width: 100%;
  max-width: 640px;
  position: relative;
  clear: both;
  text-align: center; }
  .p0-btn2_inner {
    width: 100%;
    padding: 15px 0;
    display: block;
    color: white;
    line-height: 1.2;
    cursor: pointer;
    border-radius: 42px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500; }
    @media (min-width: 600px) {
      .p0-btn2_inner {
        padding: 20px 0; } }
  .p0-btn2_inner {
    padding: 20px 0;
    background: #fc922a;
    font-size: 2.1rem; }
    @media (min-width: 600px) {
      .p0-btn2_inner {
        padding: 23px 0;
        font-size: 3.2rem; } }
  @media (min-width: 760px) {
    .p0-btn2 {
      width: 640px; } }

.p0-btn3 {
  width: 100%;
  max-width: 350px;
  clear: both;
  text-align: center; }
  @media (min-width: 600px) {
    .p0-btn3 {
      width: 350px; } }
  .p0-btn3_inner {
    width: 100%;
    padding: 15px 0;
    display: block;
    color: white;
    line-height: 1.2;
    cursor: pointer;
    border-radius: 42px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500; }
    @media (min-width: 600px) {
      .p0-btn3_inner {
        padding: 20px 0; } }
  .p0-btn3_inner {
    background: #fc922a;
    font-size: 1.6rem; }
    @media (min-width: 600px) {
      .p0-btn3_inner {
        font-size: 2rem; } }
  @media (max-width: 600px) {
    .p0-btn3 {
      max-width: 280px; } }
  .p0-btn3.-back .p0-btn3_inner {
    background: #8f8c28; }

.p0-btn4 {
  width: 100%;
  max-width: 240px;
  clear: both;
  text-align: center; }
  @media (min-width: 600px) {
    .p0-btn4 {
      width: 240px; } }
  .p0-btn4_inner {
    width: 100%;
    padding: 15px 0;
    display: block;
    color: white;
    line-height: 1.2;
    cursor: pointer;
    border-radius: 42px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500; }
    @media (min-width: 600px) {
      .p0-btn4_inner {
        padding: 20px 0; } }
  .p0-btn4_inner {
    background: #b3c51f;
    font-size: 1.44rem; }
    @media (min-width: 600px) {
      .p0-btn4_inner {
        font-size: 1.8rem; } }
  @media (max-width: 600px) {
    .p0-btn4 {
      max-width: 240px; } }

.p0-hero {
  padding-bottom: 0; }
  @media all and (min-width: 800px) {
    .p0-hero {
      padding-bottom: 20px; } }
  @media all and (min-width: 800px) {
    .p0-hero.-front {
      margin-top: -15px; } }
  @media all and (min-width: 600px) {
    .p0-hero.-front .p0-hero_content {
      width: calc(95% + 20px); } }
  @media all and (min-width: 1120px) {
    .p0-hero.-front .p0-hero_content {
      width: calc( 1000px + 20px + ( (100% - 1000px ) / 2 )); } }
  @media all and (min-width: 1320px) {
    .p0-hero.-front .p0-hero_content {
      width: calc( 1000px + 20px + ( (100% - 1000px ) / 2 ));
      margin: 0 0 0 auto; } }
  @media all and (min-width: 1800px) {
    .p0-hero.-front .p0-hero_content {
      width: 1400px;
      transform: translateX(-20px); } }
  @media all and (min-width: 1820px) {
    .p0-hero.-front .p0-hero_content {
      width: 1400px;
      margin: 0 auto 0 calc( ( ( 100% - 1000px ) / 2 ) - 20px);
      transform: translateX(0); } }
  .p0-hero.-sub {
    background-image: url(../images/common/flower02.png), url(../images/common/flower03.png);
    background-size: 9.5833333333333vw, 13.4166666666667vw;
    /* 115px / 1200px, 161px / 1200px */
    background-position: calc(6px - 4.79166666666665vw) calc(100% - 20px), calc(100% + 3px + 6.70833333333335vw) bottom;
    background-repeat: no-repeat, no-repeat; }
    @media (min-width: 600px) {
      .p0-hero.-sub {
        background-position: calc(12px - 4.79166666666665vw) calc(100% - 20px), calc(100% + 6px + 6.70833333333335vw) bottom; } }
    @media (min-width: 800px) {
      .p0-hero.-sub {
        background-position: calc(12px - 4.79166666666665vw) calc(100% - 20px), calc(100% + 6px + 6.70833333333335vw) bottom; } }
    @media (min-width: 1120px) {
      .p0-hero.-sub {
        background-position: calc(50% - 500px - 25px - 57px) calc(100% - 20px), calc(50% + 500px + 12px + 80px) bottom; } }
    @media (min-width: 1200px) {
      .p0-hero.-sub {
        background-size: 115px, 161px;
        background-position: calc(50% - 500px - 50px - 57px) calc(100% - 20px), calc(50% + 500px + 25px + 80px) bottom;
        /* calc(50%[中心] - 500px[KV幅/2] - 50px[KVから花までの余白] - 57px[花画像幅/2]) calc(50%[中心] + 500px[KV幅/2] + 25px[KVから花までの余白] + 80px[花画像/2]) */ } }
  .p0-hero_slider {
    position: relative;
    padding: 0 0 20px; }
    @media (min-width: 600px) {
      .p0-hero_slider {
        padding: 30px 0 0 20px; } }
    .p0-hero_slider_item {
      position: relative; }
      .p0-hero_slider_item img {
        display: block; }
      .p0-hero_slider_item .-txt {
        position: absolute;
        top: 0;
        left: 0;
        display: none; }
        @media (min-width: 600px) {
          .p0-hero_slider_item .-txt {
            display: block; } }
    .p0-hero_slider_dots {
      position: absolute;
      left: 3%;
      bottom: 33px; }
      @media (min-width: 600px) {
        .p0-hero_slider_dots {
          left: 0; } }
      .p0-hero_slider_dots ul {
        width: 10px; }
      .p0-hero_slider_dots li {
        display: block;
        width: 10px;
        height: 10px;
        margin-bottom: 10px;
        border-radius: 100px;
        background: rgba(255, 255, 255, 0.8); }
        .p0-hero_slider_dots li:last-child {
          margin-bottom: 0; }
      .p0-hero_slider_dots button {
        width: 10px;
        height: 10px;
        background: rgba(251, 87, 133, 0.4);
        text-indent: -9999px;
        border-radius: 100px; }
      .p0-hero_slider_dots .slick-active button {
        background: #fb5785; }
  .p0-hero.-sub .p0-hero_img {
    display: block; }
  .p0-hero_wrap {
    display: flex;
    align-items: center;
    padding: 15px 0 0 0; }
    @media (min-width: 600px) {
      .p0-hero_wrap {
        padding: 15px 0 0 0; } }
  .p0-hero_headline img {
    display: block; }
  .p0-hero_headline.-front {
    width: 90%;
    max-width: 300px;
    display: block;
    margin: auto; }
    @media (min-width: 600px) {
      .p0-hero_headline.-front {
        position: absolute;
        bottom: 50px;
        left: 65px; } }
    .p0-hero_headline.-front .catchcopy.-pc {
      display: none; }
      @media (min-width: 600px) {
        .p0-hero_headline.-front .catchcopy.-pc {
          display: block; } }
    .p0-hero_headline.-front .catchcopy.-sp {
      display: block; }
      @media (min-width: 600px) {
        .p0-hero_headline.-front .catchcopy.-sp {
          display: none; } }
  .p0-hero_headline.-page {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 1; }

.p0-separatebox {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
  background-color: #f1f3f1; }
  .p0-separatebox_item {
    width: 100%;
    padding: 5%;
    text-align: center; }
    @media (min-width: 600px) {
      .p0-separatebox_item {
        width: 50%; } }
    @media (min-width: 800px) {
      .p0-separatebox_item {
        padding: 40px 45px;
        text-align: center; } }
  .p0-separatebox.-recruit .p0-separatebox_item.-img {
    height: 60vw;
    background: url(../images/front/recruit.jpg) center top/cover no-repeat; }
    @media (min-width: 600px) {
      .p0-separatebox.-recruit .p0-separatebox_item.-img {
        height: inherit; } }
  .p0-separatebox.-recruit .frame {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 10px solid rgba(189, 213, 91, 0.4); }
  .p0-separatebox.-recruit .p0-separatebox_item {
    position: relative;
    z-index: 2; }
    .p0-separatebox.-recruit .p0-separatebox_item.-img {
      position: relative;
      z-index: 0; }

.p0-officebox {
  padding: 5%;
  position: relative; }
  @media (min-width: 1120px) {
    .p0-officebox {
      padding: 0 60px; } }
  .p0-officebox-headline {
    text-align: center;
    width: 100%;
    padding: 0 1vw 0 1vw; }
    @media (min-width: 840px) {
      .p0-officebox-headline {
        padding: 0 .5vw 0 .5vw; } }
    @media (min-width: 920px) {
      .p0-officebox-headline {
        padding: 0 20px 0 20px; } }
    .p0-officebox-headline.-first {
      margin-top: -5px !important; }
      @media (min-width: 600px) {
        .p0-officebox-headline.-first {
          margin-top: -10px !important; } }
  .p0-officebox_cimg {
    position: relative;
    z-index: 1;
    width: 40vw;
    /* 320px / 800px */
    min-width: 200px;
    margin: 0 auto; }
    @media (min-width: 800px) {
      .p0-officebox_cimg {
        width: 320px; } }
  .p0-officebox_en img {
    width: auto;
    height: 33px; }
  .p0-officebox_lead {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch; }
    .p0-officebox_lead .-ttl {
      width: 100%;
      margin-bottom: 20px;
      padding-right: 15px;
      box-sizing: border-box;
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 2.56rem;
      color: #453c35;
      line-height: 1.5; }
      @media all and (min-width: 600px) {
        .p0-officebox_lead .-ttl {
          padding-right: 30px;
          font-size: 3.2rem; } }
      @media all and (min-width: 650px) {
        .p0-officebox_lead .-ttl {
          width: 40%;
          margin-bottom: 0; } }
    .p0-officebox_lead .-text {
      width: 100%; }
      @media all and (min-width: 650px) {
        .p0-officebox_lead .-text {
          width: 60%; } }
  .p0-officebox_info {
    position: relative;
    width: 100%;
    padding: 5% 0;
    background: white; }
    @media (min-width: 800px) {
      .p0-officebox_info {
        padding: 25px 0; } }
    .p0-officebox_info:before, .p0-officebox_info:after {
      display: block;
      position: absolute;
      top: 0;
      content: "";
      width: 100vw;
      height: 100%;
      background: white; }
    .p0-officebox_info:before {
      left: 0;
      transform: translateX(-100%); }
    .p0-officebox_info:after {
      right: 0;
      z-index: 1;
      transform: translateX(100%); }
    .p0-officebox_info_item {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
      line-height: 1.8;
      padding: 10px 5px;
      background-position: left 5px;
      background-repeat: no-repeat; }
      .p0-officebox_info_item.-address {
        background-image: url("../images/office/icon-address.png");
        background-size: 25px auto; }
      .p0-officebox_info_item.-gj {
        background-image: url("../images/office/icon-tel.png");
        background-size: 27px auto; }
      .p0-officebox_info_item.-bh {
        background-image: url("../images/office/icon-bh.png");
        background-size: 29px auto; }
      .p0-officebox_info_item .sp {
        display: none; }
        @media (min-width: 840px) {
          .p0-officebox_info_item .sp {
            display: inline-block; } }
      .p0-officebox_info_item .-label {
        width: 120px;
        padding: 0 0 0 30px;
        box-sizing: border-box;
        font-size: 1.4rem;
        font-family: 'Noto Sans JP', sans-serif;
        font-style: normal;
        font-weight: 700;
        color: #515025;
        letter-spacing: 1px;
        background-image: url("../images/common/icon_tri_right_or.png");
        background-position: right 8px;
        background-repeat: no-repeat;
        background-size: 8px auto; }
        @media (min-width: 650px) {
          .p0-officebox_info_item .-label {
            width: 120px;
            padding: 0 0 0 35px; } }
        @media (min-width: 800px) {
          .p0-officebox_info_item .-label {
            width: 130px;
            padding: 0 0 0 40px; } }
      .p0-officebox_info_item .-text {
        width: 100%;
        padding: 10px 0 0 0;
        box-sizing: border-box;
        font-family: 'Noto Sans JP', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        color: #453c35;
        line-height: 1.4; }
        @media (min-width: 650px) {
          .p0-officebox_info_item .-text {
            width: calc(100% - 120px);
            padding: 0 0 0 25px;
            font-size: 1.6rem;
            line-height: 1; } }
        @media (min-width: 800px) {
          .p0-officebox_info_item .-text {
            width: calc(100% - 130px);
            padding: 0 0 0 40px; } }
      .p0-officebox_info_item .-maplink {
        width: 100%;
        max-width: 300px;
        border-radius: 100px;
        line-height: 1;
        margin-top: 10px;
        transition: all .3s ease-in-out; }
        .p0-officebox_info_item .-maplink.-pk {
          background: #fb5785; }
          .p0-officebox_info_item .-maplink.-pk:hover, .p0-officebox_info_item .-maplink.-pk:focus {
            background: #fc922a; }
        .p0-officebox_info_item .-maplink.-org {
          background: #fc922a; }
          .p0-officebox_info_item .-maplink.-org:hover, .p0-officebox_info_item .-maplink.-org:focus {
            background: #fb5785; }
        .p0-officebox_info_item .-maplink a {
          display: block;
          width: 100%;
          padding: 5px 3px 7px;
          font-size: 90%;
          text-align: center;
          color: white;
          letter-spacing: .06rem;
          font-weight: normal;
          font-style: normal;
          font-family: "genju-m",sans-serif; }
      .p0-officebox_info_item .-spotIcon {
        display: inline-block;
        padding-right: 3px;
        vertical-align: -2px;
        box-sizing: content-box;
        fill: white;
        width: 12px;
        height: 16px; }
  .p0-officebox_wrap {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 0; }
    @media all and (min-width: 840px) {
      .p0-officebox_wrap {
        padding-bottom: 60px;
        margin-bottom: 40px; } }
    @media all and (min-width: 900px) {
      .p0-officebox_wrap {
        margin-bottom: 100px; } }
    @media all and (min-width: 1180px) {
      .p0-officebox_wrap {
        padding-bottom: 132px; } }
    .p0-officebox_wrap:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0; }
  .p0-officebox_img {
    width: 100%;
    display: block; }
    @media (min-width: 840px) {
      .p0-officebox_img {
        display: none; } }
    .p0-officebox_img img {
      display: block; }
  .p0-officebox_floatingImg {
    display: none;
    position: absolute; }
    .p0-officebox_floatingImg img {
      display: block; }
    @media (min-width: 840px) {
      .p0-officebox_floatingImg {
        display: block;
        width: calc(100% - 340px - 10%);
        left: 0;
        bottom: 0; } }
    @media (min-width: 1000px) {
      .p0-officebox_floatingImg {
        width: calc(100% - 340px - 15%);
        left: 5%; } }
    @media (min-width: 1120px) {
      .p0-officebox_floatingImg {
        width: calc(100% - 520px);
        left: 60px; } }
    .p0-officebox_floatingImg.-even {
      left: inherit; }
      @media (min-width: 840px) {
        .p0-officebox_floatingImg.-even {
          right: 0; } }
      @media (min-width: 1000px) {
        .p0-officebox_floatingImg.-even {
          right: 5%; } }
      @media (min-width: 1120px) {
        .p0-officebox_floatingImg.-even {
          right: 60px; } }
  .p0-officebox_map {
    position: relative;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    width: 100vw;
    padding: 20px 0 20px;
    box-sizing: border-box; }
  .p0-officebox_bg {
    position: relative;
    margin-top: -100px;
    padding-top: 150px;
    box-sizing: border-box; }
    @media (min-width: 500px) {
      .p0-officebox_bg {
        /* 円写真の幅が200pxになるウィンドウ幅が500px */
        margin-top: -20vw;
        /* 160px / 800px */
        padding-top: 26.25vw;
        /* 210px / 800px */ } }
    @media (min-width: 800px) {
      .p0-officebox_bg {
        margin-top: -160px;
        padding-top: 210px; } }
    .p0-officebox_bg.-kunitachi:before, .p0-officebox_bg.-kunitachi:after, .p0-officebox_bg.-kokin:before, .p0-officebox_bg.-kokin:after, .p0-officebox_bg.-mitsubachi:before, .p0-officebox_bg.-mitsubachi:after {
      display: block;
      position: absolute;
      top: 0;
      content: "";
      width: 100vw;
      height: 100%; }
    .p0-officebox_bg.-kunitachi:before, .p0-officebox_bg.-kokin:before, .p0-officebox_bg.-mitsubachi:before {
      left: 0;
      transform: translateX(-100%); }
    .p0-officebox_bg.-kunitachi:after, .p0-officebox_bg.-kokin:after, .p0-officebox_bg.-mitsubachi:after {
      right: 0;
      transform: translateX(100%); }
    .p0-officebox_bg.-kunitachi {
      background: #fff4ea; }
      .p0-officebox_bg.-kunitachi:before, .p0-officebox_bg.-kunitachi:after {
        background: #fff4ea; }
    .p0-officebox_bg.-kokin {
      background: #fffcf1; }
      .p0-officebox_bg.-kokin:before, .p0-officebox_bg.-kokin:after {
        background: #fffcf1; }
    .p0-officebox_bg.-mitsubachi {
      background: #f5f9e7; }
      .p0-officebox_bg.-mitsubachi:before, .p0-officebox_bg.-mitsubachi:after {
        background: #f5f9e7; }
  @media (min-width: 840px) {
    .p0-officebox.-even .p0-officebox-headline {
      padding-left: 5%;
      order: 2; } }
  @media (min-width: 1120px) {
    .p0-officebox.-even .p0-officebox-headline {
      padding-left: 60px; } }
  @media (min-width: 840px) {
    .p0-officebox.-even .p0-officebox_info {
      order: 1; } }

.p0-roundedbox {
  padding: 5%;
  background: white;
  text-align: justify;
  border-radius: 20px;
  line-height: 1.75; }
  @media (min-width: 800px) {
    .p0-roundedbox {
      padding: 30px; } }

.p0-meritbox {
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 5;
  max-width: 600px; }
  @media (min-width: 600px) {
    .p0-meritbox {
      width: 66.6666666666667vw;
      /* 600px / 900px */ } }
  @media (min-width: 900px) {
    .p0-meritbox {
      width: 600px; } }
  .p0-meritbox_item {
    position: relative;
    z-index: 6;
    width: 47%;
    /* 280px / 600px */
    padding: 0 3%;
    box-sizing: border-box; }
    @media (min-width: 600px) {
      .p0-meritbox_item {
        width: 31.1111111111111vw;
        /* 280px / 900px */
        padding: 0 2.2222222222222vw;
        /* 20px / 900px */ } }
    @media (min-width: 900px) {
      .p0-meritbox_item {
        width: 280px;
        padding: 0 20px; } }
    .p0-meritbox_item.-merit3 {
      margin-top: -3.3333333333333vw;
      /* 30px / 600px */
      margin-bottom: 10px; }
      @media (min-width: 600px) {
        .p0-meritbox_item.-merit3 {
          margin-top: -3.3333333333333vw;
          /* 30px / 900px */
          margin-bottom: 0; } }
      @media (min-width: 900px) {
        .p0-meritbox_item.-merit3 {
          margin-top: -30px; } }
  .p0-meritbox_joinus {
    position: relative;
    z-index: 5;
    width: 70%;
    max-width: 300px;
    margin-top: -14vw; }
    @media (min-width: 600px) {
      .p0-meritbox_joinus {
        position: absolute;
        top: 0;
        right: calc(2.2222222222222vw * 4);
        z-index: 5;
        transform: translate(100%, -32%);
        width: 63.8888888888889vw;
        /* 575px / 900px */
        max-width: 575px;
        margin-top: 0; } }
    @media (min-width: 900px) {
      .p0-meritbox_joinus {
        right: 80px;
        width: 575px; } }

.p0-recruitpiece {
  background: white; }
  .p0-recruitpiece_img {
    width: 100%;
    height: 61vw;
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat; }
    @media (min-width: 520px) {
      .p0-recruitpiece_img {
        height: 29vw; } }
    @media (min-width: 740px) {
      .p0-recruitpiece_img {
        height: 18vw; } }
    @media (min-width: 1120px) {
      .p0-recruitpiece_img {
        height: 200px; } }
  .p0-recruitpiece_txtarea {
    text-align: center;
    padding: 0 5% 5%; }
    @media (min-width: 520px) {
      .p0-recruitpiece_txtarea {
        padding: 0 10% 8%; } }
    @media (min-width: 900px) {
      .p0-recruitpiece_txtarea {
        padding: 0 30px 15px; } }
    .p0-recruitpiece_txtarea .-ttl {
      padding: 15px 0;
      font-size: 1.8rem;
      line-height: 1.5;
      color: #443b34; }
      @media (min-width: 520px) {
        .p0-recruitpiece_txtarea .-ttl {
          height: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 2rem; } }
      @media (min-width: 800px) {
        .p0-recruitpiece_txtarea .-ttl {
          font-size: 2.2rem; } }
    .p0-recruitpiece_txtarea .-br {
      display: none; }
      @media (min-width: 520px) {
        .p0-recruitpiece_txtarea .-br {
          display: inline-block; } }
    .p0-recruitpiece_txtarea .-slash {
      display: inline-block;
      padding: 0 3px; }
      @media (min-width: 520px) {
        .p0-recruitpiece_txtarea .-slash {
          display: none; } }
    .p0-recruitpiece_txtarea .-more {
      border-top: 2px solid #fc922a;
      color: #fe6a07;
      line-height: 1;
      padding: 15px 0 15px 5%;
      font-size: 1.6rem;
      font-weight: normal;
      font-style: normal;
      font-family: "genju-m",sans-serif; }
    .p0-recruitpiece_txtarea .-arrow {
      display: inline-block;
      padding-left: 5%;
      box-sizing: content-box;
      fill: #fe6a07;
      width: 11px;
      height: 10px; }

.p0-jobdetails {
  background: #f1f3f1;
  padding: 5%; }
  @media (min-width: 1120px) {
    .p0-jobdetails {
      padding: 60px; } }
  .p0-jobdetails_headline {
    border-bottom: 2px solid #fc922a;
    text-align: left !important;
    padding-left: 0.5vw;
    padding-right: 0.5vw; }
    @media (min-width: 600px) {
      .p0-jobdetails_headline {
        padding-left: 0.75vw;
        padding-right: 0.75vw; } }
    @media (min-width: 900px) {
      .p0-jobdetails_headline {
        padding-left: 10px;
        padding-right: 10px; } }
    .p0-jobdetails_headline .-inner:after {
      content: '・'; }
    .p0-jobdetails_headline .-inner:last-child:after {
      content: none; }
  .p0-jobdetails_guidelines {
    background: white;
    padding: 5%; }
    @media (min-width: 1120px) {
      .p0-jobdetails_guidelines {
        padding: 30px; } }
    .p0-jobdetails_guidelines_item {
      border-bottom: 1px solid #dee0de;
      padding-top: 20px;
      padding-bottom: 20px; }
      .p0-jobdetails_guidelines_item:first-child {
        padding-top: 0; }
      .p0-jobdetails_guidelines_item:last-child {
        border: none; }

.p0-decoration {
  display: block; }
  .p0-decoration img {
    display: block; }
  .p0-decoration.-bee01 {
    width: 54px;
    display: block;
    position: absolute;
    left: inherit;
    right: 6vw;
    bottom: inherit;
    top: 80px;
    transform: scale(-1, 1); }
    @media (min-width: 500px) {
      .p0-decoration.-bee01 {
        left: 47vw;
        right: inherit; } }
    @media (min-width: 700px) {
      .p0-decoration.-bee01 {
        width: 60px;
        top: inherit;
        bottom: 50px;
        right: 20%;
        left: inherit; } }
  .p0-decoration.-bee02 {
    width: 38px;
    display: block;
    position: absolute;
    left: 5vw;
    top: inherit;
    bottom: 90px; }
    @media (min-width: 400px) {
      .p0-decoration.-bee02 {
        left: 5vw;
        top: inherit;
        bottom: 35px; } }
    @media (min-width: 600px) {
      .p0-decoration.-bee02 {
        width: 48px;
        left: 9vw;
        top: -5px;
        bottom: inherit; } }
    @media (min-width: 700px) {
      .p0-decoration.-bee02 {
        width: 58px; } }
    @media (min-width: 1000px) {
      .p0-decoration.-bee02 {
        width: 68px; } }
  .p0-decoration.-bee03 {
    width: 36px;
    position: absolute;
    top: -24px;
    right: 25%;
    transform: scale(-1, 1); }
    @media (min-width: 600px) {
      .p0-decoration.-bee03 {
        width: 48px;
        top: -24px;
        right: 250px; } }
    @media (min-width: 940px) {
      .p0-decoration.-bee03 {
        width: 57px;
        right: 0;
        left: 400px;
        margin: auto; } }
  .p0-decoration.-bee04 {
    width: 36px;
    position: absolute;
    bottom: 0;
    left: 3%; }
    @media (min-width: 600px) {
      .p0-decoration.-bee04 {
        width: 48px;
        top: 28px;
        left: 3%; } }
    @media (min-width: 800px) {
      .p0-decoration.-bee04 {
        width: 57px;
        top: 74px;
        right: 0;
        left: -520px;
        margin: auto; } }
  .p0-decoration.-bee05 {
    width: 38px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: inherit;
    margin: auto; }
    @media (min-width: 600px) {
      .p0-decoration.-bee05 {
        width: 48px;
        left: 3%;
        right: inherit;
        top: inherit;
        bottom: 5vw; } }
    @media (min-width: 700px) {
      .p0-decoration.-bee05 {
        width: 58px; } }
    @media (min-width: 1000px) {
      .p0-decoration.-bee05 {
        width: 68px; } }
    @media (min-width: 1120px) {
      .p0-decoration.-bee05 {
        left: calc(50% - 500px - 30px); } }
  .p0-decoration.-bee06 {
    display: block;
    position: absolute;
    top: 50%;
    right: -9vw;
    width: 38px;
    transform: translate(100%, -50%) scale(-1, 1);
    margin: auto; }
    @media (min-width: 600px) {
      .p0-decoration.-bee06 {
        width: 48px; } }
    @media (min-width: 700px) {
      .p0-decoration.-bee06 {
        width: 58px; } }
    @media (min-width: 1000px) {
      .p0-decoration.-bee06 {
        width: 68px;
        right: -90px; } }
  .p0-decoration.-bee07 {
    display: block;
    position: absolute;
    top: 50%;
    left: -9vw;
    width: 38px;
    transform: translate(-100%, -50%);
    margin: auto; }
    @media (min-width: 600px) {
      .p0-decoration.-bee07 {
        width: 48px; } }
    @media (min-width: 700px) {
      .p0-decoration.-bee07 {
        width: 58px; } }
    @media (min-width: 1000px) {
      .p0-decoration.-bee07 {
        width: 68px;
        left: -90px; } }
  .p0-decoration.-leaf {
    width: 110px;
    position: absolute;
    left: -5%;
    bottom: -20px; }
    @media (min-width: 600px) {
      .p0-decoration.-leaf {
        left: -4%; } }
    @media (min-width: 800px) {
      .p0-decoration.-leaf {
        width: 131px;
        left: 4.5%; } }
    @media (min-width: 1120px) {
      .p0-decoration.-leaf {
        left: calc(50% - 1010px / 2); } }
  .p0-decoration.-flower01 {
    position: absolute;
    left: 0x;
    bottom: 10px;
    transform: translate(-75%, 0);
    width: 134px; }
    @media (min-width: 600px) {
      .p0-decoration.-flower01 {
        bottom: 90px; } }
    @media (min-width: 800px) {
      .p0-decoration.-flower01 {
        transform: translate(-80%, 0); } }
    @media (min-width: 1120px) {
      .p0-decoration.-flower01 {
        left: -10px;
        width: 161px;
        transform: translate(-80%, 0); } }
    @media (min-width: 1150px) {
      .p0-decoration.-flower01 {
        transform: translate(-90%, 0); } }
    @media (min-width: 1200px) {
      .p0-decoration.-flower01 {
        transform: translate(-100%, 0); } }
  .p0-decoration.-flower04 {
    position: absolute;
    right: 0;
    top: 0;
    right: 50%;
    transform: translateX(100%);
    width: 142px; }
    @media (min-width: 600px) {
      .p0-decoration.-flower04 {
        right: calc(50% + 50px - 600px / 2);
        transform: translateX(0); } }
    @media (min-width: 800px) {
      .p0-decoration.-flower04 {
        width: 170px;
        right: calc(50% + 100px - 800px / 2); } }
    @media (min-width: 1120px) {
      .p0-decoration.-flower04 {
        right: calc(50% + 100px - 1000px / 2); } }
  .p0-decoration.-flower05 {
    position: absolute;
    left: -5%;
    bottom: 0;
    width: 102px; }
    @media (min-width: 600px) {
      .p0-decoration.-flower05 {
        left: -4%; } }
    @media (min-width: 800px) {
      .p0-decoration.-flower05 {
        width: 122px;
        left: 4.5%; } }
    @media (min-width: 1120px) {
      .p0-decoration.-flower05 {
        left: calc(50% - 1010px / 2); } }
  .p0-decoration.-flower06 {
    position: absolute;
    right: -5%;
    bottom: 0;
    width: 68px; }
    @media (min-width: 600px) {
      .p0-decoration.-flower06 {
        right: -4%; } }
    @media (min-width: 800px) {
      .p0-decoration.-flower06 {
        width: 82px;
        right: 4.5%; } }
    @media (min-width: 1120px) {
      .p0-decoration.-flower06 {
        right: calc(50% - 1000px / 2); } }
  .p0-decoration.-flower07 {
    position: absolute;
    top: 60px;
    left: -7.1428571428571vw;
    width: 21.6071428571429vw; }
    @media (min-width: 600px) {
      .p0-decoration.-flower07 {
        left: -7.1428571428571vw; } }
    @media (min-width: 800px) {
      .p0-decoration.-flower07 {
        width: 242px;
        left: -7.1428571428571vw; } }
    @media (min-width: 1120px) {
      .p0-decoration.-flower07 {
        left: -80px; } }
  .p0-decoration.-ftlogo {
    width: 102px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 480px) {
      .p0-decoration.-ftlogo {
        left: auto;
        right: 0;
        transform: translateX(0); } }
    @media (min-width: 800px) {
      .p0-decoration.-ftlogo {
        width: 122px;
        bottom: 60px; } }

.p1-logo {
  width: calc(100% - 60px*2);
  display: block;
  position: relative;
  z-index: 1200; }
  @media (min-width: 800px) {
    .p1-logo {
      width: 100%; } }
  .p1-logo_inner {
    width: 90%;
    margin: 0 auto;
    position: relative; }
    @media (min-width: 1120px) {
      .p1-logo_inner {
        width: 1000px; } }
  .p1-logo_item {
    display: block; }
    .p1-logo_item.-olivier {
      width: 100px;
      position: absolute;
      top: 0;
      left: 0;
      display: none; }
      @media (min-width: 800px) {
        .p1-logo_item.-olivier {
          display: block; } }
    .p1-logo_item.-caremane {
      width: 436px;
      margin: 28px auto 0;
      display: none; }
      @media (min-width: 800px) {
        .p1-logo_item.-caremane {
          display: block;
          transform: translate(0.8125vw, 0); } }
      @media (min-width: 900px) {
        .p1-logo_item.-caremane {
          transform: translate(1.625vw, 0); } }
      @media (min-width: 1000px) {
        .p1-logo_item.-caremane {
          transform: translate(3.25vw, 0); } }
      @media (min-width: 1080px) {
        .p1-logo_item.-caremane {
          transform: translate(6.5vw, 0); } }
      @media (min-width: 1180px) {
        .p1-logo_item.-caremane {
          transform: translate(52px, 0); } }
    .p1-logo_item.-comp {
      width: 140px;
      position: fixed;
      top: 10px;
      left: 5%; }
      @media (min-width: 800px) {
        .p1-logo_item.-comp {
          width: 176px;
          top: 8px; } }
  .p1-logo_img {
    display: block; }
    .p1-logo_img.-full {
      display: none; }
      @media (min-width: 1000px) {
        .p1-logo_img.-full {
          display: block; } }
    .p1-logo_img.-slim {
      display: block; }
      @media (min-width: 1000px) {
        .p1-logo_img.-slim {
          display: none; } }

.p1-drawer-hidden {
  display: none; }

.p1-drawer-open {
  /* ハンバーガーアイコンの設置スペース */
  display: flex;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  z-index: 1200;
  /* 重なり順を一番上に */
  cursor: pointer;
  /* ハンバーガーメニューのアイコン */ }
  @media all and (min-width: 800px) {
    .p1-drawer-open {
      display: none; } }
  .p1-drawer-open span, .p1-drawer-open span:before, .p1-drawer-open span:after {
    content: '';
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background: #333;
    transition: 0.5s;
    position: absolute; }
  .p1-drawer-open span::before {
    bottom: 8px; }
  .p1-drawer-open span::after {
    top: 8px; }

#drawer-check:checked ~ .p1-drawer-open span {
  /* アイコンがクリックされたら真ん中の線を透明に */
  background: rgba(255, 255, 255, 0);
  /* アイコンがクリックされたらアイコンが×印になように上下の線を回転 */ }
  #drawer-check:checked ~ .p1-drawer-open span::before {
    bottom: 0;
    transform: rotate(45deg); }
  #drawer-check:checked ~ .p1-drawer-open span::after {
    top: 0;
    transform: rotate(-45deg); }

#drawer-check:checked ~ .p1-gnav_content {
  top: 0;
  /* アイコンがクリックされたらメニューを表示 */ }

.p1-gnav {
  width: 100%;
  background: #f1f3f1;
  position: fixed;
  top: 0;
  left: 0; }
  @media all and (min-width: 800px) {
    .p1-gnav {
      background: transparent; } }
  .p1-gnav::before {
    content: '';
    background: white;
    display: block;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000; }
    @media all and (min-width: 800px) {
      .p1-gnav::before {
        display: none;
        background: rgba(255, 255, 255, 0.85); } }
  .p1-gnav_content {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: -100vh;
    z-index: 99;
    background: rgba(241, 243, 241, 0.95);
    transition: .5s; }
    @media all and (min-width: 800px) {
      .p1-gnav_content {
        height: inherit;
        position: static;
        background: rgba(255, 255, 255, 0.85); } }
  .p1-gnav_list {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: calc(10px + 60px) 0 0 0; }
    @media all and (min-width: 800px) {
      .p1-gnav_list {
        flex-direction: row;
        justify-content: center;
        padding: 0; } }
  .p1-gnav_item {
    display: block;
    transition: all linear; }
  .p1-gnav_itemInner {
    display: block;
    color: #665c54;
    font-size: 2rem;
    text-align: center;
    transition: all linear;
    line-height: 1;
    padding: 15px 0;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500; }
    @media all and (min-width: 800px) {
      .p1-gnav_itemInner {
        font-size: 2vw;
        padding: 23px 1.2vw;
        line-height: 1; } }
    @media all and (min-width: 940px) {
      .p1-gnav_itemInner {
        font-size: 2rem;
        padding: 23px 22px; } }
    .p1-gnav_itemInner:hover, .p1-gnav_itemInner:focus {
      color: #fe6a07; }
    .p1-gnav_itemInner.is-current {
      color: #fc922a; }
  .p1-gnav.is-head {
    position: relative; }
    .p1-gnav.is-head .p1-gnav_content {
      background: rgba(255, 255, 255, 0); }
  .p1-gnav_list.-contact {
    z-index: 5000; }
    @media (min-width: 800px) {
      .p1-gnav_list.-contact {
        width: 180px;
        flex-direction: column;
        position: fixed;
        top: 25px;
        right: 3%; } }
    @media (min-width: 1000px) {
      .p1-gnav_list.-contact {
        width: 200px; } }
  .p1-gnav_list.-contact .p1-gnav_item {
    margin: 5px 0; }
    @media (min-width: 800px) {
      .p1-gnav_list.-contact .p1-gnav_item {
        margin: 5px 0 5px 5px; } }
    .p1-gnav_list.-contact .p1-gnav_item .p1-gnav_itemInner {
      padding: 14px 3px 16px;
      color: white;
      border-radius: 25px; }
    .p1-gnav_list.-contact .p1-gnav_item.-anything .p1-gnav_itemInner {
      background: #fc922a; }
    .p1-gnav_list.-contact .p1-gnav_item.-entry .p1-gnav_itemInner {
      background: #b3c51f; }
  .p1-gnav_list.-contact.is-scroll {
    width: 290px;
    flex-direction: row;
    justify-content: flex-end;
    top: 5px; }
    .p1-gnav_list.-contact.is-scroll .p1-gnav_item {
      width: 16vw; }
      @media (min-width: 940px) {
        .p1-gnav_list.-contact.is-scroll .p1-gnav_item {
          width: 140px; } }
    .p1-gnav_list.-contact.is-scroll .p1-gnav_itemInner {
      font-size: 1.5rem; }
      @media (min-width: 800px) {
        .p1-gnav_list.-contact.is-scroll .p1-gnav_itemInner {
          font-size: 1.8vw; } }
      @media (min-width: 940px) {
        .p1-gnav_list.-contact.is-scroll .p1-gnav_itemInner {
          font-size: 1.5rem; } }

.p1-fixBtmNav {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: white; }
  .p1-fixBtmNav_item {
    width: calc(100% / 3);
    transition: all .3s ease-in-out; }
    .p1-fixBtmNav_item.-kunitachi {
      background: #fdb670; }
      .p1-fixBtmNav_item.-kunitachi:hover, .p1-fixBtmNav_item.-kunitachi:focus {
        background: #f18e30; }
    .p1-fixBtmNav_item.-kokin {
      background: #ffea9f; }
      .p1-fixBtmNav_item.-kokin:hover, .p1-fixBtmNav_item.-kokin:focus {
        background: #efc543; }
    .p1-fixBtmNav_item.-mitsubachi {
      background: #bdd55b; }
      .p1-fixBtmNav_item.-mitsubachi:hover, .p1-fixBtmNav_item.-mitsubachi:focus {
        background: #b4c524; }
  .p1-fixBtmNav_itemInner {
    width: 100%;
    display: block;
    padding: 8px 0; }
    @media (min-width: 600px) {
      .p1-fixBtmNav_itemInner {
        padding: 10px 0; } }
    @media (min-width: 900px) {
      .p1-fixBtmNav_itemInner {
        padding: 15px 0; } }
  .p1-fixBtmNav_itemImg {
    display: block;
    margin: auto;
    width: auto;
    height: 6.3vw;
    max-height: 50px; }
    @media (min-width: 600px) {
      .p1-fixBtmNav_itemImg {
        height: 5.6vw; } }
    @media (min-width: 900px) {
      .p1-fixBtmNav_itemImg {
        height: 50px; } }

.p2-copyright {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1;
  padding: 8px 0;
  color: white;
  background: #515025; }

.p2-ftinfo {
  margin-bottom: 150px;
  font-size: 1.4rem;
  line-height: 1.6;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start; }
  @media (min-width: 480px) {
    .p2-ftinfo {
      margin-bottom: 40px; } }
  @media (min-width: 700px) {
    .p2-ftinfo {
      flex-flow: row nowrap;
      margin-bottom: 70px; } }
  @media (min-width: 700px) {
    .p2-ftinfo_item {
      padding-right: 6%; } }
  @media (min-width: 800px) {
    .p2-ftinfo_item {
      padding-right: 8%; } }
  .p2-ftinfo_item:last-child {
    padding-right: 0; }
  .p2-ftinfo_item.-item2 {
    padding-top: 15px; }
    @media (min-width: 700px) {
      .p2-ftinfo_item.-item2 {
        padding-top: 0; } }
  .p2-ftinfo_txt {
    margin-bottom: 10px; }
    .p2-ftinfo_txt.-logo-care {
      width: 246px;
      max-width: 246px; }
    .p2-ftinfo_txt.-logo-olivier {
      width: 122px;
      max-width: 122px; }
    .p2-ftinfo_txt.-bold {
      font-size: 1.5rem;
      font-weight: 700; }
    .p2-ftinfo_txt .-link {
      border-bottom: 1px solid #665c54; }
      .p2-ftinfo_txt .-link.-link2 {
        color: #515025; }
      .p2-ftinfo_txt .-link.-noline {
        border-bottom-style: none; }
    .p2-ftinfo_txt .-maplink {
      font-weight: 700;
      color: white;
      background: #fc922a;
      font-size: 1.2rem;
      padding: 0 3px 1px;
      margin-left: 5px; }

.p3-headline {
  text-align: center;
  color: #443b34;
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 600;
  font-weight: 600;
  /* フォントカラー */ }
  .p3-headline.-page01 {
    display: none;
    font-size: 2.4vw;
    margin-bottom: 10px;
    line-height: 1;
    position: relative;
    z-index: 10;
    font-weight: normal;
    font-style: normal;
    font-family: "genju-m",sans-serif; }
    @media (min-width: 460px) {
      .p3-headline.-page01 {
        display: inline-block; } }
    @media (min-width: 640px) {
      .p3-headline.-page01 {
        margin-bottom: 15px; } }
    @media (min-width: 800px) {
      .p3-headline.-page01 {
        font-size: 2.2rem;
        margin-bottom: 25px; } }
  .p3-headline.-page02 {
    font-size: 6.66vw;
    line-height: 1; }
    @media (min-width: 460px) {
      .p3-headline.-page02 {
        font-size: 5.41vw; } }
    @media (min-width: 640px) {
      .p3-headline.-page02 {
        font-size: 4.58vw; } }
    @media (min-width: 800px) {
      .p3-headline.-page02 {
        font-size: 4.5rem; } }
  .p3-headline.-cnt {
    margin-top: 0;
    margin-bottom: 25px;
    line-height: 1.2;
    position: relative;
    font-size: 2.1rem;
    font-weight: normal; }
    @media (min-width: 600px) {
      .p3-headline.-cnt {
        margin-top: 0;
        margin-bottom: 50px;
        font-size: 3rem; } }
  .p3-headline.-cnt2 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 3.5rem;
    color: #453c35; }
    @media (min-width: 600px) {
      .p3-headline.-cnt2 {
        margin-top: 20px;
        font-size: 4.5rem;
        margin-bottom: 20px; } }
    @media (min-width: 800px) {
      .p3-headline.-cnt2 {
        margin-bottom: 40px; } }
  .p3-headline.-cnt3 {
    margin-top: 30px; }
    @media (min-width: 600px) {
      .p3-headline.-cnt3 {
        margin-top: 8vw; } }
    @media (min-width: 900px) {
      .p3-headline.-cnt3 {
        margin-top: 70px; } }
  .p3-headline.-cnt4 {
    margin-bottom: 15px;
    font-size: 3.42rem;
    color: #453c35;
    line-height: 1.36; }
    @media (min-width: 600px) {
      .p3-headline.-cnt4 {
        margin-bottom: 30px;
        font-size: 4.4rem; } }
  .p3-headline.-cnt5 {
    margin-bottom: 15px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 4.66rem;
    color: #443b34;
    line-height: 1; }
    @media (min-width: 600px) {
      .p3-headline.-cnt5 {
        margin-bottom: 30px;
        font-size: 6rem; } }
  .p3-headline .-main {
    position: relative;
    z-index: 10; }
  .p3-headline .-main2 {
    position: relative;
    z-index: 10; }
  .p3-headline .-small {
    font-size: 1.6rem;
    margin-bottom: 5px;
    position: relative;
    z-index: 10;
    font-weight: normal;
    font-style: normal;
    font-family: "genju-m",sans-serif; }
    @media (min-width: 600px) {
      .p3-headline .-small {
        font-size: 2.2rem;
        margin-bottom: 17px; } }
  .p3-headline .-en {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 1.3rem;
    color: #fc922a; }
    @media (min-width: 600px) {
      .p3-headline .-en {
        font-size: 1.8rem; } }
    .p3-headline .-en.-border:after {
      display: block;
      position: relative;
      content: "";
      width: 36px;
      height: 2px;
      margin: 15px auto 0;
      background-color: #fc922a; }
      @media (min-width: 600px) {
        .p3-headline .-en.-border:after {
          margin-top: 25px; } }
  .p3-headline .-script, .p3-headline.-script {
    position: absolute;
    z-index: 0; }
    .p3-headline .-script img, .p3-headline.-script img {
      display: block; }
    .p3-headline .-script.-policy, .p3-headline.-script.-policy {
      width: 40vw;
      max-width: 314px;
      min-width: 160px;
      bottom: -13vw;
      left: 0;
      right: 0;
      margin: auto; }
      @media (min-width: 800px) {
        .p3-headline .-script.-policy, .p3-headline.-script.-policy {
          width: 314px;
          bottom: -108px; } }
    .p3-headline .-script.-recruit, .p3-headline.-script.-recruit {
      width: 24vw;
      max-width: 183px;
      min-width: 120px;
      z-index: 1;
      top: 30px;
      left: 7%; }
      @media (min-width: 800px) {
        .p3-headline .-script.-recruit, .p3-headline.-script.-recruit {
          width: 183px;
          top: 60px;
          left: calc(5% + 40px); } }
      @media (min-width: 1120px) {
        .p3-headline .-script.-recruit, .p3-headline.-script.-recruit {
          top: 60px;
          left: -395px;
          right: 360px;
          margin: auto; } }
    .p3-headline .-script.-contact, .p3-headline.-script.-contact {
      width: 120px;
      max-width: 205px;
      top: -34px;
      right: 1.5%; }
      @media (min-width: 600px) {
        .p3-headline .-script.-contact, .p3-headline.-script.-contact {
          width: 180px;
          right: 5%; } }
      @media (min-width: 940px) {
        .p3-headline .-script.-contact, .p3-headline.-script.-contact {
          width: 205px;
          top: 10px;
          left: 0;
          right: -688px;
          margin: auto; } }
    .p3-headline .-script.-joinus, .p3-headline.-script.-joinus {
      width: 30vw;
      max-width: 269px;
      min-width: 130px;
      right: 3vw;
      bottom: -9vw; }
      @media (min-width: 600px) {
        .p3-headline .-script.-joinus, .p3-headline.-script.-joinus {
          bottom: -11vw; } }
      @media (min-width: 800px) {
        .p3-headline .-script.-joinus, .p3-headline.-script.-joinus {
          right: 3vw;
          bottom: -65px; } }
      @media (min-width: 1060px) {
        .p3-headline .-script.-joinus, .p3-headline.-script.-joinus {
          width: 269px;
          right: 100px;
          bottom: -65px; } }
    .p3-headline .-script.-jobs, .p3-headline.-script.-jobs {
      width: 20vw;
      max-width: 160px;
      min-width: 108px;
      margin: auto;
      top: -30px;
      left: -164px;
      right: 0; }
      @media (min-width: 600px) {
        .p3-headline .-script.-jobs, .p3-headline.-script.-jobs {
          top: -8vw; } }
      @media (min-width: 900px) {
        .p3-headline .-script.-jobs, .p3-headline.-script.-jobs {
          width: 160px;
          top: -61px; } }
  .p3-headline.-sub {
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 30px; }
    @media (min-width: 600px) {
      .p3-headline.-sub {
        font-size: 4rem;
        margin-bottom: 60px; } }
  .p3-headline.-sub2 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 600;
    font-weight: 600;
    line-height: 1.6;
    font-size: 5vw;
    margin-top: 0;
    margin-bottom: 20px; }
    @media (min-width: 600px) {
      .p3-headline.-sub2 {
        font-size: 3rem;
        margin-bottom: 40px; } }
    @media (min-width: 800px) {
      .p3-headline.-sub2 {
        margin-bottom: 80px;
        font-size: 4rem;
        line-height: 1.875; } }
  .p3-headline.-sub3 {
    font-size: 15px;
    line-height: 1.86;
    letter-spacing: 0.375vw; }
    @media (min-width: 400px) {
      .p3-headline.-sub3 {
        font-size: 3.75vw; } }
    @media (min-width: 600px) {
      .p3-headline.-sub3 {
        font-size: 2.25rem;
        margin-bottom: 40px; } }
    @media (min-width: 800px) {
      .p3-headline.-sub3 {
        margin-bottom: 80px;
        font-size: 3rem;
        line-height: 2.1;
        letter-spacing: 3px; } }
  .p3-headline.-sub4 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #443b34;
    line-height: 1.6;
    font-size: 3.6vw;
    /* 21.6px / 600px  */
    margin-top: 0;
    margin-bottom: 20px; }
    @media (min-width: 600px) {
      .p3-headline.-sub4 {
        font-size: 2.7rem;
        margin-bottom: 40px; } }
    @media (min-width: 800px) {
      .p3-headline.-sub4 {
        margin-bottom: 80px;
        font-size: 3.6rem;
        line-height: 1.875; } }
  .p3-headline.-fc_or {
    color: #fe6a07; }
  .p3-headline.-fc_db {
    color: #453c35; }

.p3-box.-frontbox01 {
  position: relative; }
  .p3-box.-frontbox01 .p3-img {
    position: relative; }
  .p3-box.-frontbox01 .p3-headline {
    display: block;
    position: absolute;
    top: 38%;
    right: 0;
    transform: translateY(-50%);
    text-align: justify; }

.p3-text .-em01 {
  font-size: 125%;
  color: #443b34;
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
  font-family: "genju-b",sans-serif; }

.p3-text .-pk, .p3-text.-pk {
  color: #fb5785 !important; }

.p3-text .-org, .p3-text.-org {
  color: #fe6a07 !important; }

.p3-text .-lorg, .p3-text.-lorg {
  color: #fc922a !important; }

.p3-text.-t01 {
  text-align: justify; }

.p3-text.-t02 {
  position: relative;
  z-index: 5;
  text-align: center;
  font-size: 2rem;
  font-weight: 700; }
  @media (min-width: 800px) {
    .p3-text.-t02 {
      font-size: 2.8rem; } }

.p3-text.-t03 {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.4; }
  @media (min-width: 800px) {
    .p3-text.-t03 {
      font-size: 2.6rem; } }

.p3-text.-t04 {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 600;
  font-weight: 600;
  font-size: 2rem;
  color: #453c35;
  line-height: 1.92; }
  @media (min-width: 800px) {
    .p3-text.-t04 {
      font-size: 2.8rem; } }

.p3-text.-t05 {
  font-size: 1.4rem; }
  @media (min-width: 800px) {
    .p3-text.-t05 {
      font-size: 1.6rem; } }

.p3-text.-t06 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #443b34;
  line-height: 1.8; }
  @media (min-width: 600px) {
    .p3-text.-t06 {
      font-size: 1.8rem;
      line-height: 2; } }
  @media (min-width: 800px) {
    .p3-text.-t06 {
      font-size: 2.2rem; } }

.p3-text.-t07 {
  line-height: 1.5;
  margin-bottom: 10px; }
  .p3-text.-t07:last-child {
    margin-bottom: 0; }

.p3-text.-t08 {
  font-size: 80%;
  line-height: 1.7; }

.p3-text.-t09 {
  padding-bottom: 20px;
  border-bottom: 2px solid #fc922a; }

.p3-text.-t10 {
  padding-bottom: 20px; }

.p3-text.-list01 {
  text-align: justify; }
  .p3-text.-list01 li {
    text-indent: -2rem;
    padding-left: 2rem; }
    @media (min-width: 600px) {
      .p3-text.-list01 li {
        text-indent: -2.4rem;
        padding-left: 2.4rem; } }
  .p3-text.-list01 .-item {
    line-height: 1.75;
    margin-bottom: 15px; }
    .p3-text.-list01 .-item:last-child {
      margin-bottom: 0; }

.p3-text.-ttl01 {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 4.4vw;
  color: #443b34;
  text-align: left;
  line-height: 1; }
  @media (min-width: 400px) {
    .p3-text.-ttl01 {
      font-size: 1.85rem; } }
  @media (min-width: 800px) {
    .p3-text.-ttl01 {
      font-size: 2.6rem; } }
  .p3-text.-ttl01 .-ttlInner01 {
    display: inline-block;
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 645px;
    padding: 15px 20px 25px 30px;
    background-image: url("../images/recruit/merit_border.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: auto 100%;
    line-height: 1.4; }
    @media (min-width: 600px) {
      .p3-text.-ttl01 .-ttlInner01 {
        padding: 20px 20px 30px 35px; } }
    @media (min-width: 600px) {
      .p3-text.-ttl01 .-ttlInner01 {
        padding: 25px 160px 35px 40px; } }
    .p3-text.-ttl01 .-ttlInner01 .-yg {
      color: #b3c51f; }

.p3-text.-ttl02 {
  font-size: 2rem;
  color: #fe6a07;
  line-height: 1.35;
  font-weight: normal;
  font-style: normal;
  font-family: "genju-b",sans-serif; }
  @media (min-width: 520px) {
    .p3-text.-ttl02 {
      text-align: center; } }
  @media (min-width: 600px) {
    .p3-text.-ttl02 {
      font-size: 2.4rem; } }
  @media (min-width: 820px) {
    .p3-text.-ttl02 {
      line-height: 1.6;
      font-size: 2.8rem; } }
  @media (max-width: 980px) {
    .p3-text.-ttl02 .br {
      display: none; } }

.p3-text.-ttl03 {
  font-size: 90%;
  line-height: 1.3;
  color: #443b34;
  font-weight: normal;
  font-style: normal;
  font-family: "genju-b",sans-serif; }

.p3-img.-i01 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -4.5454545454545vw; }
  @media (min-width: 1320px) {
    .p3-img.-i01 {
      margin-left: -60px; } }
  .p3-img.-i01 img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto; }
  .p3-img.-i01 .-i01_1 {
    position: relative;
    width: 48%;
    max-width: 480px; }
    .p3-img.-i01 .-i01_1:after {
      display: block;
      position: absolute;
      top: 3.030303030303vw;
      /* 40px / 1320px */
      right: 11.7424242424242vw;
      /* 155px / 1320px */
      z-index: -1;
      width: 100vw;
      height: 100%;
      content: "";
      background-color: #fffbed; }
      @media (min-width: 1320px) {
        .p3-img.-i01 .-i01_1:after {
          top: 40px;
          right: 155px; } }
  .p3-img.-i01 .-i01_2 {
    position: relative;
    width: 30%;
    max-width: 300px;
    margin-top: 7.5757575757576vw;
    /* 100px / 1320px */
    margin-left: 6.8181818181818vw;
    /* 90px / 1320px */ }
    @media (min-width: 1320px) {
      .p3-img.-i01 .-i01_2 {
        margin-top: 100px;
        margin-left: 90px; } }
    .p3-img.-i01 .-i01_2:after {
      display: block;
      position: absolute;
      top: -4.5454545454545vw;
      /* 60px / 1320px */
      left: 14.3939393939394vw;
      /* 190px / 1320px */
      z-index: -1;
      width: 100vw;
      height: 100%;
      content: "";
      background-color: #fffbed; }
      @media (min-width: 1320px) {
        .p3-img.-i01 .-i01_2:after {
          top: -60px;
          left: 190px; } }

.p3-img.-i03 {
  width: 53.7%;
  max-width: 537px; }

.p3-img.-i04 img {
  display: block;
  width: 100%;
  margin: 0 auto; }

.p4-postarea h4 {
  font-size: 2rem;
  font-weight: bold; }

.p4-postarea h5 {
  font-size: 1.7rem;
  font-weight: bold; }

.p4-postarea img {
  width: 100%;
  height: inherit; }
  @media all and (min-width: 650px) and (max-width: 799px) {
    .p4-postarea img.vertically-S {
      width: inherit; } }
  @media all and (min-width: 800px) {
    .p4-postarea img.vertically-S {
      width: inherit; } }
  @media all and (min-width: 650px) and (max-width: 799px) {
    .p4-postarea img.horizontally-S {
      width: inherit; } }
  @media all and (min-width: 800px) {
    .p4-postarea img.horizontally-S {
      width: inherit; } }

.p4-pager {
  clear: both;
  text-align: center;
  margin-bottom: 30px; }
  @media all and (min-width: 800px) {
    .p4-pager {
      margin-bottom: 60px; } }
  .p4-pager span {
    margin: 0 2px; }
    .p4-pager span.current {
      background: sub03(1);
      color: wh(1);
      padding: 5px 9px;
      margin: 0 2px; }
  .p4-pager a {
    padding: 5px 9px;
    margin: 0 5px;
    color: txt01(1);
    text-decoration: none; }
    .p4-pager a:hover, .p4-pager a:focus {
      color: effect01(1); }
    .p4-pager a.page {
      background: base02(0.8); }
      .p4-pager a.page:hover, .p4-pager a.page:focus {
        background: effect01(1);
        color: wh(1); }
  .p4-pager .page-numbers {
    background: base02(0.8); }
    .p4-pager .page-numbers:hover, .p4-pager .page-numbers:focus {
      background: effect01(1);
      color: wh(1); }
    .p4-pager .page-numbers.next, .p4-pager .page-numbers.prev {
      background: transparent; }
      .p4-pager .page-numbers.next:hover, .p4-pager .page-numbers.next:focus, .p4-pager .page-numbers.prev:hover, .p4-pager .page-numbers.prev:focus {
        background: transparent;
        color: effect01(1); }

.p5-form {
  width: 100%;
  max-width: 600px;
  margin: auto; }
  .p5-form_input {
    background-color: #f1f3f1;
    padding: 3px 5%;
    font-size: 1.6rem;
    margin-bottom: 15px; }
    @media all and (min-width: 600px) {
      .p5-form_input {
        padding: 8px 15px;
        margin-bottom: 30px; } }
    .p5-form_input:focus {
      border: 2px solid rgba(0, 0, 0, 0.5); }
    .p5-form_input.-txt {
      width: 100%; }
    .p5-form_input.-check {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      vertical-align: 3px; }
  .p5-form .-link {
    margin-bottom: 15px; }
    .p5-form .-link a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: white;
      background: #b3c51f;
      border-radius: 100px;
      font-weight: normal;
      font-style: normal;
      font-family: "genju-m",sans-serif; }
      @media all and (min-width: 600px) {
        .p5-form .-link a {
          width: 310px;
          max-width: 310px;
          padding: 10px 20px;
          font-size: 1.7rem; } }
  .p5-form_btn {
    margin: 0 10px; }
  .p5-form_btns {
    display: flex;
    justify-content: center;
    margin: 30px 0 !important;
    border-bottom: none !important; }
    @media all and (min-width: 600px) {
      .p5-form_btns {
        margin: 60px 0 !important; } }

.mwform-checkbox-field-text {
  vertical-align: 5px;
  padding-left: 5px; }

.mwform-checkbox-field {
  line-height: 1; }

.mw_wp_form .error {
  display: block;
  color: #fe6a07 !important;
  margin: -20px 0 30px;
  font-size: 90%;
  font-weight: 700; }

.mw_wp_form_input .p5-form_btn.-back, .mw_wp_form_input .p5-form_btn.-submit, .mw_wp_form_input .p5-form_btn.-top {
  display: none; }

.mw_wp_form_input .p5-form_thx {
  display: none; }

.mw_wp_form_confirm .p5-form_btn.-confirm, .mw_wp_form_confirm .p5-form_btn.-top {
  display: none; }

.mw_wp_form_confirm .error {
  display: none; }

.mw_wp_form_confirm .p3-text.-ttl03 {
  padding-left: 1vw;
  padding-right: 1vw; }
  @media (min-width: 600px) {
    .mw_wp_form_confirm .p3-text.-ttl03 {
      padding-left: 1.5vw;
      padding-right: 1.5vw; } }
  @media (min-width: 900px) {
    .mw_wp_form_confirm .p3-text.-ttl03 {
      padding-left: 20px;
      padding-right: 20px; } }

.mw_wp_form_confirm .p3-text.-ttl03 ~ div {
  margin-bottom: 15px;
  padding-bottom: 15px;
  line-height: 1.5;
  border-bottom: 1px solid #dee0de;
  padding-left: 1vw;
  padding-right: 1vw; }
  @media (min-width: 600px) {
    .mw_wp_form_confirm .p3-text.-ttl03 ~ div {
      padding-left: 1.5vw;
      padding-right: 1.5vw; } }
  @media (min-width: 900px) {
    .mw_wp_form_confirm .p3-text.-ttl03 ~ div {
      padding-left: 20px;
      padding-right: 20px; } }
  @media all and (min-width: 600px) {
    .mw_wp_form_confirm .p3-text.-ttl03 ~ div {
      margin-bottom: 30px;
      padding-bottom: 30px; } }
  .mw_wp_form_confirm .p3-text.-ttl03 ~ div:last-child {
    border: none; }

.mw_wp_form_confirm .formarea-privacy-wrap {
  display: none; }

.mw_wp_form_confirm .p5-form_thx {
  display: none; }

.mw_wp_form_complete .p5-form_btn.-back, .mw_wp_form_complete .p5-form_btn.-confirm, .mw_wp_form_complete .p5-form_btn.xzssxsaazaa-submit {
  display: none; }

.mw_wp_form_complete ~ .p5-form_intro {
  display: none; }

.u-m0 {
  margin: 0px !important; }

.u-mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.u-mlr0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.u-mt0 {
  margin-top: 0px !important; }

.u-mb0 {
  margin-bottom: 0px !important; }

.u-ml0 {
  margin-left: 0px !important; }

.u-mr0 {
  margin-right: 0px !important; }

.u-p0 {
  padding: 0px !important; }

.u-pt0 {
  padding-top: 0px !important; }

.u-pb0 {
  padding-bottom: 0px !important; }

.u-pl0 {
  padding-left: 0px !important; }

.u-pr0 {
  padding-right: 0px !important; }

.u-m5 {
  margin: 5px !important; }

.u-mtb5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.u-mlr5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mb5 {
  margin-bottom: 5px !important; }

.u-ml5 {
  margin-left: 5px !important; }

.u-mr5 {
  margin-right: 5px !important; }

.u-p5 {
  padding: 5px !important; }

.u-pt5 {
  padding-top: 5px !important; }

.u-pb5 {
  padding-bottom: 5px !important; }

.u-pl5 {
  padding-left: 5px !important; }

.u-pr5 {
  padding-right: 5px !important; }

.u-m10 {
  margin: 10px !important; }

.u-mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.u-mlr10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mb10 {
  margin-bottom: 10px !important; }

.u-ml10 {
  margin-left: 10px !important; }

.u-mr10 {
  margin-right: 10px !important; }

.u-p10 {
  padding: 10px !important; }

.u-pt10 {
  padding-top: 10px !important; }

.u-pb10 {
  padding-bottom: 10px !important; }

.u-pl10 {
  padding-left: 10px !important; }

.u-pr10 {
  padding-right: 10px !important; }

.u-m15 {
  margin: 15px !important; }

.u-mtb15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.u-mlr15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mb15 {
  margin-bottom: 15px !important; }

.u-ml15 {
  margin-left: 15px !important; }

.u-mr15 {
  margin-right: 15px !important; }

.u-p15 {
  padding: 15px !important; }

.u-pt15 {
  padding-top: 15px !important; }

.u-pb15 {
  padding-bottom: 15px !important; }

.u-pl15 {
  padding-left: 15px !important; }

.u-pr15 {
  padding-right: 15px !important; }

.u-m20 {
  margin: 20px !important; }

.u-mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.u-mlr20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mb20 {
  margin-bottom: 20px !important; }

.u-ml20 {
  margin-left: 20px !important; }

.u-mr20 {
  margin-right: 20px !important; }

.u-p20 {
  padding: 20px !important; }

.u-pt20 {
  padding-top: 20px !important; }

.u-pb20 {
  padding-bottom: 20px !important; }

.u-pl20 {
  padding-left: 20px !important; }

.u-pr20 {
  padding-right: 20px !important; }

.u-m25 {
  margin: 25px !important; }

.u-mtb25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.u-mlr25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mb25 {
  margin-bottom: 25px !important; }

.u-ml25 {
  margin-left: 25px !important; }

.u-mr25 {
  margin-right: 25px !important; }

.u-p25 {
  padding: 25px !important; }

.u-pt25 {
  padding-top: 25px !important; }

.u-pb25 {
  padding-bottom: 25px !important; }

.u-pl25 {
  padding-left: 25px !important; }

.u-pr25 {
  padding-right: 25px !important; }

.u-m30 {
  margin: 30px !important; }

.u-mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.u-mlr30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mb30 {
  margin-bottom: 30px !important; }

.u-ml30 {
  margin-left: 30px !important; }

.u-mr30 {
  margin-right: 30px !important; }

.u-p30 {
  padding: 30px !important; }

.u-pt30 {
  padding-top: 30px !important; }

.u-pb30 {
  padding-bottom: 30px !important; }

.u-pl30 {
  padding-left: 30px !important; }

.u-pr30 {
  padding-right: 30px !important; }

.u-m35 {
  margin: 35px !important; }

.u-mtb35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.u-mlr35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mb35 {
  margin-bottom: 35px !important; }

.u-ml35 {
  margin-left: 35px !important; }

.u-mr35 {
  margin-right: 35px !important; }

.u-p35 {
  padding: 35px !important; }

.u-pt35 {
  padding-top: 35px !important; }

.u-pb35 {
  padding-bottom: 35px !important; }

.u-pl35 {
  padding-left: 35px !important; }

.u-pr35 {
  padding-right: 35px !important; }

.u-m40 {
  margin: 40px !important; }

.u-mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.u-mlr40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mb40 {
  margin-bottom: 40px !important; }

.u-ml40 {
  margin-left: 40px !important; }

.u-mr40 {
  margin-right: 40px !important; }

.u-p40 {
  padding: 40px !important; }

.u-pt40 {
  padding-top: 40px !important; }

.u-pb40 {
  padding-bottom: 40px !important; }

.u-pl40 {
  padding-left: 40px !important; }

.u-pr40 {
  padding-right: 40px !important; }

.u-m45 {
  margin: 45px !important; }

.u-mtb45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.u-mlr45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mb45 {
  margin-bottom: 45px !important; }

.u-ml45 {
  margin-left: 45px !important; }

.u-mr45 {
  margin-right: 45px !important; }

.u-p45 {
  padding: 45px !important; }

.u-pt45 {
  padding-top: 45px !important; }

.u-pb45 {
  padding-bottom: 45px !important; }

.u-pl45 {
  padding-left: 45px !important; }

.u-pr45 {
  padding-right: 45px !important; }

.u-m50 {
  margin: 50px !important; }

.u-mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.u-mlr50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mb50 {
  margin-bottom: 50px !important; }

.u-ml50 {
  margin-left: 50px !important; }

.u-mr50 {
  margin-right: 50px !important; }

.u-p50 {
  padding: 50px !important; }

.u-pt50 {
  padding-top: 50px !important; }

.u-pb50 {
  padding-bottom: 50px !important; }

.u-pl50 {
  padding-left: 50px !important; }

.u-pr50 {
  padding-right: 50px !important; }

.u-m55 {
  margin: 55px !important; }

.u-mtb55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.u-mlr55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mb55 {
  margin-bottom: 55px !important; }

.u-ml55 {
  margin-left: 55px !important; }

.u-mr55 {
  margin-right: 55px !important; }

.u-p55 {
  padding: 55px !important; }

.u-pt55 {
  padding-top: 55px !important; }

.u-pb55 {
  padding-bottom: 55px !important; }

.u-pl55 {
  padding-left: 55px !important; }

.u-pr55 {
  padding-right: 55px !important; }

.u-m60 {
  margin: 60px !important; }

.u-mtb60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.u-mlr60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mb60 {
  margin-bottom: 60px !important; }

.u-ml60 {
  margin-left: 60px !important; }

.u-mr60 {
  margin-right: 60px !important; }

.u-p60 {
  padding: 60px !important; }

.u-pt60 {
  padding-top: 60px !important; }

.u-pb60 {
  padding-bottom: 60px !important; }

.u-pl60 {
  padding-left: 60px !important; }

.u-pr60 {
  padding-right: 60px !important; }

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-mb10_half {
  margin-bottom: 5px !important; }
  @media all and (min-width: 600px) {
    .u-mb10_half {
      margin-bottom: 10px !important; } }

.u-mb20_half {
  margin-bottom: 10px !important; }
  @media all and (min-width: 600px) {
    .u-mb20_half {
      margin-bottom: 20px !important; } }

.u-mb30_half {
  margin-bottom: 15px !important; }
  @media all and (min-width: 600px) {
    .u-mb30_half {
      margin-bottom: 30px !important; } }

.u-mb40_half {
  margin-bottom: 20px !important; }
  @media all and (min-width: 600px) {
    .u-mb40_half {
      margin-bottom: 40px !important; } }

.u-mb50_half {
  margin-bottom: 25px !important; }
  @media all and (min-width: 600px) {
    .u-mb50_half {
      margin-bottom: 50px !important; } }

.u-mb60_half {
  margin-bottom: 30px !important; }
  @media all and (min-width: 600px) {
    .u-mb60_half {
      margin-bottom: 60px !important; } }

.u-mb70_half {
  margin-bottom: 35px !important; }
  @media all and (min-width: 600px) {
    .u-mb70_half {
      margin-bottom: 70px !important; } }

.u-mb80_half {
  margin-bottom: 40px !important; }
  @media all and (min-width: 600px) {
    .u-mb80_half {
      margin-bottom: 80px !important; } }

.u-mb90_half {
  margin-bottom: 45px !important; }
  @media all and (min-width: 600px) {
    .u-mb90_half {
      margin-bottom: 90px !important; } }

.u-mb15_23rd {
  margin-bottom: 10px !important; }
  @media all and (min-width: 600px) {
    .u-mb15_23rd {
      margin-bottom: 15px !important; } }

.u-mb30_23rd {
  margin-bottom: 20px !important; }
  @media all and (min-width: 600px) {
    .u-mb30_23rd {
      margin-bottom: 30px !important; } }

.u-mb45_23rd {
  margin-bottom: 30px !important; }
  @media all and (min-width: 600px) {
    .u-mb45_23rd {
      margin-bottom: 45px !important; } }

.u-mb60_23rd {
  margin-bottom: 40px !important; }
  @media all and (min-width: 600px) {
    .u-mb60_23rd {
      margin-bottom: 60px !important; } }

.u-mb75_23rd {
  margin-bottom: 50px !important; }
  @media all and (min-width: 600px) {
    .u-mb75_23rd {
      margin-bottom: 75px !important; } }

.u-mb90_23rd {
  margin-bottom: 60px !important; }
  @media all and (min-width: 600px) {
    .u-mb90_23rd {
      margin-bottom: 90px !important; } }

.u-mb105_23rd {
  margin-bottom: 70px !important; }
  @media all and (min-width: 600px) {
    .u-mb105_23rd {
      margin-bottom: 105px !important; } }

.u-mb120_23rd {
  margin-bottom: 80px !important; }
  @media all and (min-width: 600px) {
    .u-mb120_23rd {
      margin-bottom: 120px !important; } }

.u-mb135_23rd {
  margin-bottom: 90px !important; }
  @media all and (min-width: 600px) {
    .u-mb135_23rd {
      margin-bottom: 135px !important; } }

.u-mb150_23rd {
  margin-bottom: 100px !important; }
  @media all and (min-width: 600px) {
    .u-mb150_23rd {
      margin-bottom: 150px !important; } }

.u-mb165_23rd {
  margin-bottom: 110px !important; }
  @media all and (min-width: 600px) {
    .u-mb165_23rd {
      margin-bottom: 165px !important; } }

.u-mb180_23rd {
  margin-bottom: 120px !important; }
  @media all and (min-width: 600px) {
    .u-mb180_23rd {
      margin-bottom: 180px !important; } }

.u-mb10_level {
  margin-bottom: 3.33333px !important; }
  @media all and (min-width: 520px) {
    .u-mb10_level {
      margin-bottom: 5px !important; } }
  @media all and (min-width: 800px) {
    .u-mb10_level {
      margin-bottom: 10px !important; } }

.u-mb20_level {
  margin-bottom: 6.66667px !important; }
  @media all and (min-width: 520px) {
    .u-mb20_level {
      margin-bottom: 10px !important; } }
  @media all and (min-width: 800px) {
    .u-mb20_level {
      margin-bottom: 20px !important; } }

.u-mb30_level {
  margin-bottom: 10px !important; }
  @media all and (min-width: 520px) {
    .u-mb30_level {
      margin-bottom: 15px !important; } }
  @media all and (min-width: 800px) {
    .u-mb30_level {
      margin-bottom: 30px !important; } }

.u-mb40_level {
  margin-bottom: 13.33333px !important; }
  @media all and (min-width: 520px) {
    .u-mb40_level {
      margin-bottom: 20px !important; } }
  @media all and (min-width: 800px) {
    .u-mb40_level {
      margin-bottom: 40px !important; } }

.u-mb50_level {
  margin-bottom: 16.66667px !important; }
  @media all and (min-width: 520px) {
    .u-mb50_level {
      margin-bottom: 25px !important; } }
  @media all and (min-width: 800px) {
    .u-mb50_level {
      margin-bottom: 50px !important; } }

.u-mb60_level {
  margin-bottom: 20px !important; }
  @media all and (min-width: 520px) {
    .u-mb60_level {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 800px) {
    .u-mb60_level {
      margin-bottom: 60px !important; } }

.u-mb70_level {
  margin-bottom: 23.33333px !important; }
  @media all and (min-width: 520px) {
    .u-mb70_level {
      margin-bottom: 35px !important; } }
  @media all and (min-width: 800px) {
    .u-mb70_level {
      margin-bottom: 70px !important; } }

.u-mb80_level {
  margin-bottom: 26.66667px !important; }
  @media all and (min-width: 520px) {
    .u-mb80_level {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 800px) {
    .u-mb80_level {
      margin-bottom: 80px !important; } }

.u-mb90_level {
  margin-bottom: 30px !important; }
  @media all and (min-width: 520px) {
    .u-mb90_level {
      margin-bottom: 45px !important; } }
  @media all and (min-width: 800px) {
    .u-mb90_level {
      margin-bottom: 90px !important; } }

.u-mb100_level {
  margin-bottom: 33.33333px !important; }
  @media all and (min-width: 520px) {
    .u-mb100_level {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 800px) {
    .u-mb100_level {
      margin-bottom: 100px !important; } }

.u-mb110_level {
  margin-bottom: 36.66667px !important; }
  @media all and (min-width: 520px) {
    .u-mb110_level {
      margin-bottom: 55px !important; } }
  @media all and (min-width: 800px) {
    .u-mb110_level {
      margin-bottom: 110px !important; } }

.u-mb120_level {
  margin-bottom: 40px !important; }
  @media all and (min-width: 520px) {
    .u-mb120_level {
      margin-bottom: 60px !important; } }
  @media all and (min-width: 800px) {
    .u-mb120_level {
      margin-bottom: 120px !important; } }

.u-mb130_level {
  margin-bottom: 43.33333px !important; }
  @media all and (min-width: 520px) {
    .u-mb130_level {
      margin-bottom: 65px !important; } }
  @media all and (min-width: 800px) {
    .u-mb130_level {
      margin-bottom: 130px !important; } }

.u-mb140_level {
  margin-bottom: 46.66667px !important; }
  @media all and (min-width: 520px) {
    .u-mb140_level {
      margin-bottom: 70px !important; } }
  @media all and (min-width: 800px) {
    .u-mb140_level {
      margin-bottom: 140px !important; } }

.u-mb150_level {
  margin-bottom: 50px !important; }
  @media all and (min-width: 520px) {
    .u-mb150_level {
      margin-bottom: 75px !important; } }
  @media all and (min-width: 800px) {
    .u-mb150_level {
      margin-bottom: 150px !important; } }

.u-mb160_level {
  margin-bottom: 53.33333px !important; }
  @media all and (min-width: 520px) {
    .u-mb160_level {
      margin-bottom: 80px !important; } }
  @media all and (min-width: 800px) {
    .u-mb160_level {
      margin-bottom: 160px !important; } }

.u-mb170_level {
  margin-bottom: 56.66667px !important; }
  @media all and (min-width: 520px) {
    .u-mb170_level {
      margin-bottom: 85px !important; } }
  @media all and (min-width: 800px) {
    .u-mb170_level {
      margin-bottom: 170px !important; } }

.u-mb180_level {
  margin-bottom: 60px !important; }
  @media all and (min-width: 520px) {
    .u-mb180_level {
      margin-bottom: 90px !important; } }
  @media all and (min-width: 800px) {
    .u-mb180_level {
      margin-bottom: 180px !important; } }

.u-mb-minus10_half {
  margin-bottom: -5px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus10_half {
      margin-bottom: -10px !important; } }

.u-mb-minus20_half {
  margin-bottom: -10px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus20_half {
      margin-bottom: -20px !important; } }

.u-mb-minus30_half {
  margin-bottom: -15px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus30_half {
      margin-bottom: -30px !important; } }

.u-mb-minus40_half {
  margin-bottom: -20px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus40_half {
      margin-bottom: -40px !important; } }

.u-mb-minus50_half {
  margin-bottom: -25px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus50_half {
      margin-bottom: -50px !important; } }

.u-mb-minus60_half {
  margin-bottom: -30px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus60_half {
      margin-bottom: -60px !important; } }

.u-mb-minus70_half {
  margin-bottom: -35px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus70_half {
      margin-bottom: -70px !important; } }

.u-mb-minus80_half {
  margin-bottom: -40px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus80_half {
      margin-bottom: -80px !important; } }

.u-mb-minus90_half {
  margin-bottom: -45px !important; }
  @media all and (min-width: 600px) {
    .u-mb-minus90_half {
      margin-bottom: -90px !important; } }

/* 画面外にいる状態 */
.js-fadeIn {
  opacity: 0.1;
  transform: translate(0, 30px);
  transition: all 1000ms; }

/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity: 1;
  transform: translate(0, 0); }
