.p4-pager {
  clear: both;
  text-align: center;
  margin-bottom: 30px;
  @media all and (min-width:800px) {
    margin-bottom: 60px;
  }
  & span {
    margin: 0 2px;
    &.current {
      background: sub03(1);
      color: wh(1);
      padding: 5px 9px;
      margin: 0 2px;
    }
  }
  & a {
    padding: 5px 9px;
    margin: 0 5px;
    color: txt01(1);
    text-decoration: none;
    &:hover , &:focus {
      color: effect01(1);
    }
    &.page {
      background: base02(0.8);
      &:hover , &:focus {
        background: effect01(1);
        color: wh(1);
      }
    }
  }
  & .page-numbers {
    background: base02(0.8);
    &:hover , &:focus {
      background: effect01(1);
      color: wh(1);
    }
    &.next, &.prev {
      background: transparent;
      &:hover, &:focus {
        background: transparent;
        color: effect01(1);
      }
    }
  }
}