@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}
@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}

@function color-base01($opacity) {
  @return rgba(255, 255, 255, $opacity);
}
@function color-base02($opacity) {
  @return rgba(241, 243, 241, $opacity); //#f1f3f1
}
@function color-base03($opacity) {
  @return rgba(143, 140, 40, $opacity); //#8f8c28 olive-
}
@function color-base04($opacity) {
  @return rgba(81, 80, 37, $opacity); //#515025 olive+
}
@function color-base05($opacity) {
  @return rgba(255, 245, 248, $opacity); //#fff5f8 pk ---
}
@function color-base06($opacity) {
  @return rgba(255, 247, 223, $opacity); //#fff7df orange ---
}
@function color-base07($opacity) {
  @return rgba(247, 255, 237, $opacity); //#f7ffed green ---
}
@function color-base08($opacity) {
  @return rgba(238, 238, 224, $opacity); //#eeeee0 olive ---
}
@function color-base09($opacity) {
  @return rgba(179, 197, 31, $opacity); //#b3c51f yellowgreen ---
}
@function color-base10($opacity) {
  @return rgba(255, 251, 237, $opacity); //#fffbed cream ---
}
@function color-base11($opacity) {
  @return rgba(255, 244, 234, $opacity); //#fff4ea orange ----
}
@function color-base12($opacity) {
  @return rgba(255, 252, 241, $opacity); //#fffcf1 cream ------
}
@function color-base13($opacity) {
  @return rgba(245, 249, 231, $opacity); //#fff4ea green ------
}


@function color-effect01($opacity) {
  @return rgba(251, 87, 133, $opacity); //#fb5785 pk
}
@function color-effect02($opacity) {
  @return rgba(252, 146, 42, $opacity); //#fc922a orange
}
@function color-effect03($opacity) {
  @return rgba(254, 106, 7, $opacity); //#fe6a07 orange+
}
@function color-effect04($opacity) {
  @return rgba(141, 200, 64, $opacity); //#8dc840 green
}
@function color-effect05($opacity) {
  @return rgba(241, 142, 48, $opacity); //#f18e30 orange++
}
@function color-effect06($opacity) {
  @return rgba(239, 197, 67, $opacity); //#efc543 yellow++
}
@function color-effect07($opacity) {
  @return rgba(180, 197, 36, $opacity); //#b4c524 green++
}


@function color-sub01($opacity) {
  @return rgba(253, 182, 112, $opacity); //fdb670 orange----
}
@function color-sub02($opacity) {
  @return rgba(255, 234, 159, $opacity); //ffea9f yellow----
}
@function color-sub03($opacity) {
  @return rgba(189, 213, 91, $opacity); //bdd55b green----
}
@function color-sub04($opacity) {
  @return rgba(247, 212, 221, $opacity); //f7d4dd pk---
}
@function color-sub05($opacity) {
  @return rgba(222, 224, 222, $opacity); //dee0de gray -- border
}

@function color-txt01($opacity) {
  @return rgba(102, 92, 84, $opacity); //#665c54 graybrown
}
@function color-txt02($opacity) {
  @return rgba(68, 59, 52, $opacity); //#443b34 brown+
}
@function color-txt03($opacity) {
  @return rgba(123, 112, 104, $opacity); //#7b7068 graybrown-
}
@function color-txt04($opacity) {
  @return rgba(69, 60, 53, $opacity); //#453c35 darkbrown
}
@function color-txt05($opacity) {
  @return rgba(81, 80, 37, $opacity); //#515025 olive+
}
@function color-txt06($opacity) {
  @return rgba(179, 197, 31, $opacity); //#b3c51f yellowgreen ---
}
