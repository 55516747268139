.p0-recruitpiece {
  background: color-wh(1);
  &_img {
    width: 100%;
    height: 61vw;
    @media (min-width:520px) {
      height: 29vw;
    }
    @media (min-width:740px) {
      height: 18vw;
    }
    @media (min-width:1120px) {
      height: 200px;
    }
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    @for $value from 1 through 3 {
//      &.-type#{$value} {
//        background-image: url(../../assets/images/recruit/job_type#{$value}.jpg);
//      }
    }
  }

  &_txtarea {
    text-align: center;
    padding: 0 5% 5%;
    @media (min-width:520px) {
      padding: 0 10% 8%;
    }
    @media (min-width:900px) {
      padding: 0 30px 15px;
    }
    & .-ttl {
      padding: 15px 0;
      font-size: 1.8rem;
      line-height: 1.5;
      color: color-txt02(1);
      @media (min-width:520px) {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2rem;
      }
      @media (min-width:800px) {
        font-size: 2.2rem;
      }
    }
    & .-br {
      display: none;
      @media (min-width:520px) {
        display: inline-block;
      }
    }
    & .-slash {
      display: inline-block;
      padding: 0 3px;
      @media (min-width:520px) {
        display: none;
      }
    }
    & .-more {
      border-top: 2px solid color-effect02(1);
      color: color-effect03(1);
      line-height: 1;
      padding: 15px 0 15px 5%;
      font-size: 1.6rem;
      @include font-gj(m);
    }
    & .-arrow {
      display: inline-block;
      padding-left: 5%;
      box-sizing: content-box;
      @include icon-arrow('recruitpiece');
    }
  }

}