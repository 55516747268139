.p4-postarea {
  h4 {
    font-size: 2rem;
    font-weight: bold;
  }
  h5 {
    font-size: 1.7rem;
    font-weight: bold;
  }
  & img {
    width: 100%;
    height: inherit;
    
    &.vertically-L {
    }
    &.vertically-S {
      @media all and (min-width:650px) and (max-width:799px) {
        width: inherit;
      }
      @media all and (min-width:800px) {
        width: inherit;
      }
    }
    &.horizontally-L {
    }
    &.horizontally-S {
      @media all and (min-width:650px) and (max-width:799px) {
        width: inherit;
      }
      @media all and (min-width:800px) {
        width: inherit;
      }
    }
  }
}