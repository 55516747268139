.p0-officebox {
  padding: 5%;
  position: relative;
  @media (min-width:1120px) {
    padding: 0 60px;
  }
  
  &-headline {
    text-align: center;
    width: 100%;
    padding: 0 1vw 0 1vw;
    @media (min-width:840px) {
      padding: 0 .5vw 0 .5vw;
    }
    @media (min-width:920px) {
      padding: 0 20px 0 20px;
    }
    
    &.-first {
      margin-top: -5px !important;
      @media (min-width:600px) {
        margin-top: -10px !important;
      }
    }
  }
  
  &_cimg {
    position: relative;
    z-index: 1;
    width: 40vw;  /* 320px / 800px */
    min-width: 200px;
    margin: 0 auto;
    @media (min-width:800px) {
      width: 320px;
    }
  }
  
  &_en {
    img {
      width: auto;
      height: 33px;
    }
  }
  
  &_lead {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    
    & .-ttl {
      width: 100%;
      margin-bottom: 20px;
      padding-right: 15px;
      box-sizing: border-box;
      @include font-ntsan("b");
      font-size: 2.56rem;
      color: color-txt04(1);
      line-height: 1.5;
      
      @media all and (min-width:600px) {
        padding-right: 30px;
        font-size: 3.2rem;
      }
      @media all and (min-width:650px) {
        width: 40%;
        margin-bottom: 0;
      }
    }
    & .-text {
      width: 100%;
      @media all and (min-width:650px) {
        width: 60%;
      }
    }
  }

  &_info {
    position: relative;
    width: 100%;
    padding: 5% 0;
    background: color-wh(1);
    @media (min-width:800px) {
      padding: 25px 0;
    }
    &:before, &:after {
      display: block;
      position: absolute;
      top: 0;
      content: "";
      width: 100vw;
      height: 100%;
      background: color-wh(1);
    }
    &:before {
      left: 0;
      transform: translateX(-100%);
    }
    &:after {
      right: 0;
      z-index: 1;
      transform: translateX(100%);
    }

    &_item {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
      line-height: 1.8;
      padding: 10px 5px;
      background-position: left 5px;
      background-repeat: no-repeat;
      &:first-child {
//        padding-top: 0;
      }
      &:last-child {
//        border: none;
//        padding-bottom: 0;
      }

      &.-address {
        background-image: url("../images/office/icon-address.png");
        background-size: 25px auto;
      }
      &.-gj {
        background-image: url("../images/office/icon-tel.png");
        background-size: 27px auto;
      }
      &.-bh {
        background-image: url("../images/office/icon-bh.png");
        background-size: 29px auto;
      }
      & .sp {
        display: none;
        @media (min-width:840px) {
          display: inline-block;
        }
      }
      & .-label {
        width: 120px;
        padding: 0 0 0 30px;
        box-sizing: border-box;
        font-size: 1.4rem;
//        margin-bottom: 15px;
        @include font-ntsan("b");
        color: color-txt05(1);
        letter-spacing: 1px;
        background-image: url("../images/common/icon_tri_right_or.png");
        background-position: right 8px;
        background-repeat: no-repeat;
        background-size: 8px auto;
        @media (min-width:650px) {
          width: 120px;
          padding: 0 0 0 35px;
        }
        @media (min-width:800px) {
          width: 130px;
          padding: 0 0 0 40px;
        }
      }
      & .-address {
      }
      & .-gj {
      }
      & .-bh {
      }
      & .-text {
        width: 100%;
//        width: calc(100% - 110px);
        padding: 10px 0 0 0;
        box-sizing: border-box;
        @include font-ntsan("b");
        font-size: 1.5rem;
        color: color-txt04(1);
        line-height: 1.4;
        @media (min-width:650px) {
          width: calc(100% - 120px);
          padding: 0 0 0 25px;
          font-size: 1.6rem;
          line-height: 1;
        }
        @media (min-width:800px) {
          width: calc(100% - 130px);
          padding: 0 0 0 40px;
        }
      }

      & .-maplink {
        width: 100%;
        max-width: 300px;
        border-radius: 100px;
        line-height: 1;
        margin-top: 10px;
        transition: all .3s ease-in-out;
        &.-pk {
          background: color-effect01(1);
          &:hover, &:focus {
            background: color-effect02(1);
          }
        }
        &.-org  {
          background: color-effect02(1);
          &:hover, &:focus {
            background: color-effect01(1);
          }
        }
        & a {
          display: block;
          width: 100%;
          padding: 5px 3px 7px;
          font-size: 90%;
          text-align: center;
          color: color-wh(1);
          letter-spacing: .06rem;
          @include font-gj(m);
        }
      }
      & .-spotIcon {
        display: inline-block;
        padding-right: 3px;
        vertical-align: -2px;
        box-sizing: content-box;
        @include icon-spot('maplink');
      }
    }
  }

  &_wrap {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 0;
    @media all and (min-width:840px) {
      padding-bottom: 60px;
      margin-bottom: 40px;
    }
    @media all and (min-width:900px) {
      margin-bottom: 100px;
    }
    @media all and (min-width:1180px) {
      padding-bottom: 132px;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &_img {
    width: 100%;
    display: block;
    @media (min-width:840px) {
      display: none;
    }
    & img { display: block; }
  }

  &_floatingImg {
    & img { display: block; }
    display: none;
    position: absolute;
    @media (min-width:840px) {
      display: block;
      width: calc(100% - 340px - 10%);
      left: 0;
      bottom: 0;
    }
    @media (min-width:1000px) {
      width: calc(100% - 340px - 15%);
      left: 5%;
    }
    @media (min-width:1120px) {
      width: calc(100% - 520px);
      left: 60px;
    }
    &.-even {
      left: inherit;
      @media (min-width:840px) {
        right: 0;
      }
      @media (min-width:1000px) {
        right: 5%;
      }
      @media (min-width:1120px) {
        right: 60px;
      }
    }
  }

  &_map {
    position: relative;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    width: 100vw;
    padding: 20px 0 20px;
    box-sizing: border-box;
  }

  &_bg {
    position: relative;
    margin-top: -100px;
    padding-top: 150px;
    box-sizing: border-box;

    @media (min-width:500px) {  /* 円写真の幅が200pxになるウィンドウ幅が500px */
      margin-top: -20vw;  /* 160px / 800px */
      padding-top: 26.25vw;  /* 210px / 800px */
    }
    @media (min-width:800px) {
      margin-top: -160px;
      padding-top: 210px;
    }

    &.-kunitachi, &.-kokin, &.-mitsubachi {
      &:before, &:after {
        display: block;
        position: absolute;
        top: 0;
        content: "";
        width: 100vw;
        height: 100%;
      }
      &:before {
        left: 0;
        transform: translateX(-100%);
      }
      &:after {
        right: 0;
        transform: translateX(100%);
      }
    }
    &.-kunitachi {
      background: color-base11(1);
      
      &:before, &:after {
        background: color-base11(1);
      }
    }
    &.-kokin {
      background: color-base12(1);
      
      &:before, &:after {
        background: color-base12(1);
      }
    }
    &.-mitsubachi {
      background: color-base13(1);
      
      &:before, &:after {
        background: color-base13(1);
      }
    }
  }

  &.-even {
    .p0-officebox-headline {
      @media (min-width:840px) {
        padding-left: 5%;
        order: 2;
      }
      @media (min-width:1120px) {
        padding-left: 60px;
      }
    }
    .p0-officebox_info {
      @media (min-width:840px) {
        order: 1;
      }
    }
  }

}