// fadein effect
/* 画面外にいる状態 */
.js-fadeIn {
  opacity : 0.1;
  transform : translate(0, 30px);
  transition : all 1000ms;
}
 
/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity : 1;
  transform : translate(0, 0);
}