.p0-separatebox {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
  background-color: color-base02(1);
  &_item {
    width: 100%;
    padding: 5%;
    text-align: center;
    @media (min-width:600px) {
      width: 50%;
    }
    @media (min-width:800px) {
      padding: 40px 45px;
      text-align: center;
    }
  }

  &.-recruit .p0-separatebox_item.-img {
    height: 60vw;
    background: url(../images/front/recruit.jpg) center top / cover no-repeat;
    @media (min-width:600px) {
      height: inherit;
    }
  }
  
  &.-recruit {
    & .frame {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 10px solid color-sub03(0.4);
    }
    
    & .p0-separatebox_item {
      position: relative;
      z-index: 2;
      
      &.-img {
        position: relative;
        z-index: 0;
      }
    }
  }
}