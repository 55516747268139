.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
  @media all and (min-width:600px) {
    padding: 60px 0;
  }
  &.-pad0 {
    padding: 0;
  }
  &.-largeSpace {
    padding: 40px 0;
    @media all and (min-width:600px) {
      padding: 80px 0;
    }

    &.-pb0 {
      padding: 40px 0 0;
      @media all and (min-width:600px) {
        padding: 80px 0 0;
      }
    }
  }
  &.-gray {
    background: color-base02(1);
  }
  &_content {
    width: 90%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    @media all and (min-width:1120px) {
      width: 1000px;
    }
    &.-wide {
      @media all and (min-width:1180px) {
        width: 1120px;
      }
    }
    &.-fullright {
      width: 95%;
      margin: 0 0 0 auto;
      position: relative;
      overflow: hidden;
      @media all and (min-width:1320px) {
        width: 1160px;
        margin: 0 auto;
      }
    }
    &.-visible {
      overflow: visible;
    }
  }
  &.-last {
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
    @media all and (min-width:600px) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }
}

.l-wrap {
}