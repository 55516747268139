////// text settings //////

@mixin fontSize($pc,$sp) {
  font-size: $sp + rem;
  @media all and (min-width:600px) {
    font-size: $pc + rem;
  }
}

@mixin lineHeight($pc,$sp) {
  line-height: $sp;
  @media all and (min-width:600px) {
    line-height: $pc;
  }
}

@mixin align($status) {
  text-align: left !important;
  @if $status == 'l' {
    @media all and (min-width:800px) { text-align: left !important; }
  } @else if $status == 'r' {
    @media all and (min-width:800px) { text-align: right !important; }
  } @else if $status == 'c' {
    @media all and (min-width:800px) { text-align: center !important; }
  }
}
.align-l { @include align(l); }
.align-r { @include align(r); }
.align-c { @include align(c); }

.br, %br {
  display: none;
  @media all and (min-width:800px) {
    display: inline-block;
  }
}

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/



////// list //////

@mixin listIndent($font-pc,$font-sp) {
  & li {
    text-indent: - $font-sp + rem;
    padding-left: $font-sp + rem;
    @media (min-width:600px) {
      text-indent: - $font-pc + rem;
      padding-left: $font-pc + rem;
    }
  }
}


////// table scroll //////
//usage:tableの一階層上に追加
@mixin tableScroll($tableW) {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  // scroll bar
  &:-webkit-scrollbar{
   height: 8px;
  }
  &::-webkit-scrollbar-track{
   background: #333;
  }
  &::-webkit-scrollbar-thumb {
   background: #999;
  }
  // table設定
  & table {
    width: $tableW + px;
  }
}


////// img cleary (transform) //////

@mixin imgCleary() {
  backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

////// tategaki //////

@mixin tategaki() {
  writing-mode: vertical-rl;
  text-align: left;
  text-orientation: upright; // 正立/横倒し mixed / upright / sideways
  //text-combine-upright: all; // 縦中横 all / digits 2~4
  //-ms-text-combine-horizontal: : digits 2;
  & .combine {
    text-combine-upright: all;
  }
}

//解除
@mixin tategaki-off() {
  writing-mode: horizontal-tb;
  text-align: inherit;
  text-orientation: inherit; // 正立/横倒し mixed / upright / sideways
  //text-combine-upright: all; // 縦中横 all / digits 2~4
  //-ms-text-combine-horizontal: : digits 2;
   & .combine {
     text-combine-upright: none;
   }
}

////// mouse over setings //////

.hover, %hover {
  filter:alpha(opacity=100);
  opacity:1;
  transition: all 0.3s ease-in-out;
  &:hover, &:focus {
    filter:alpha(opacity=70);
    opacity:0.7;
  }
}

////// anchor //////

.anchor, %anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block;
  @media (min-width: 800px) {
    top: -90px;
  }
}

////// clearfix //////

@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

////// ... //////
//@include abridgement(行間,文字数,表示したい行数,#背景色);
@mixin abridgement($line-height,$font-size,$lines,$color){
  line-height: $line-height;
  height: $font-size*$line-height*$lines+px;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  text-align:justify;
  &:before,
  &:after{
    position: absolute;
  }
  &:before{
    content: '...';
    background: $color;
    right: 0px;
    text-align: center;
    width:1em !important;
    top:$font-size*$line-height*($lines - 1) +px;
  }
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    background: 0px;
    z-index: 2;
    background: $color;
  }
}

// inner box padding setting

@mixin innerBox() {
  padding-left: 1vw;
  padding-right: 1vw;
  @media (min-width:600px) {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  @media (min-width:900px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}


@mixin innerBox2() {
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  @media (min-width:600px) {
    padding-left: 0.75vw;
    padding-right: 0.75vw;
  }
  @media (min-width:900px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}