.l-header {
  width: 100%;
  //background: color-base02(1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  @media all and (min-width:800px) {
    position: relative;
    //overflow: hidden;
  }
}