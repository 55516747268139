.p3-text {
  & .-em01 {
    font-size: 125%;
    color: color-txt02(1);
    line-height: 1.2;
    @include font-gj(b);
  }
  & .-pk, &.-pk {
    color: color-effect01(1) !important;
  }
  & .-org, &.-org {
    color: color-effect03(1) !important;
  }
  & .-lorg, &.-lorg {
    color: color-effect02(1) !important;
  }
  &.-t01 {
    text-align: justify;
  }
  &.-t02 {
    position: relative;
    z-index: 5;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    @media (min-width:800px) {
      font-size: 2.8rem;
    }
  }
  &.-t03 {
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 1.4;
    @media (min-width:800px) {
      font-size: 2.6rem;
    }
  }
  &.-t04 {
    @include font-shipporimin("sb");
    font-size: 2rem;
    color: color-txt04(1);
    line-height: 1.92;
    @media (min-width:800px) {
      font-size: 2.8rem;
    }
  }
  &.-t05 {
    font-size: 1.4rem;
    @media (min-width:800px) {
      font-size: 1.6rem;
    }
  }
  &.-t06 {
    font-size: 1.5rem;
    font-weight: 700;
    color: color-txt02(1);
    line-height: 1.8;
    @media (min-width:600px) {
      font-size: 1.8rem;
      line-height: 2;
    }
    @media (min-width:800px) {
      font-size: 2.2rem;
    }
  }
  &.-t07 {
    line-height: 1.5;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.-t08 {
    font-size: 80%;
    line-height: 1.7;
  }
  &.-t09 {
    padding-bottom: 20px;
    border-bottom: 2px solid color-effect02(1);
  }
  &.-t10 {
    padding-bottom: 20px;
  }

  &.-list01 {
    text-align: justify;
    @include listIndent(2.4,2);
    & .-item {
      line-height: 1.75;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.-ttl01 {
    @include font-ntsan("m");
    font-size: 4.4vw;
    color: color-txt02(1);
    text-align: left;
    line-height: 1;
    @media (min-width:400px) {
      font-size: 1.85rem;
    }
    @media (min-width:800px) {
      font-size: 2.6rem;
    }
    & .-ttlInner01 {
      display: inline-block;
      position: relative;
      z-index: 5;
      width: 100%;
      max-width: 645px;
      padding: 15px 20px 25px 30px;
      background-image: url("../images/recruit/merit_border.png");
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: auto 100%;
      line-height: 1.4;
      @media (min-width:600px) {
        padding: 20px 20px 30px 35px;
      }
      @media (min-width:600px) {
        padding: 25px 160px 35px 40px;
      }
      
      & .-yg {
        color: color-txt06(1);
      }
    }
  }
  &.-ttl02 {
    font-size: 2rem;
    color: color-effect03(1);
    line-height: 1.35;
    @include font-gj(b);
    @media (min-width:520px) {
      text-align: center;
    }
    @media (min-width:600px) {
      font-size: 2.4rem;
    }
    @media (min-width:820px) {
      line-height: 1.6;
      font-size: 2.8rem;
    }
    & .br {
      @media (max-width:980px) {
        display: none;
      }
    }
  }

  &.-ttl03 {
    font-size: 90%;
    line-height: 1.3;
    color: color-txt02(1);
    @include font-gj(b);
  }

}