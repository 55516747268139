.p3-img {
  &.-i01 {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -4.5454545454545vw;
    @media (min-width:1320px) {
      margin-left: -60px;
    }

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: auto;
    }
    
    & .-i01_1 {
      position: relative;
      width: 48%;
      max-width: 480px;
      
      &:after {
        display: block;
        position: absolute;
        top: 3.030303030303vw; /* 40px / 1320px */
        right: 11.7424242424242vw; /* 155px / 1320px */
        z-index: -1;
        width: 100vw;
        height: 100%;
        content: "";
        background-color: color-base10(1);
        @media (min-width:1320px) {
          top: 40px;
          right: 155px;
        }
      }
    }
    & .-i01_2 {
      position: relative;
      width: 30%;
      max-width: 300px;
      margin-top: 7.5757575757576vw; /* 100px / 1320px */
      margin-left: 6.8181818181818vw; /* 90px / 1320px */
      @media (min-width:1320px) {
        margin-top: 100px;
        margin-left: 90px;
      }
      
      &:after {
        display: block;
        position: absolute;
        top: -4.5454545454545vw; /* 60px / 1320px */
        left: 14.3939393939394vw; /* 190px / 1320px */
        z-index: -1;
        width: 100vw;
        height: 100%;
        content: "";
        background-color: color-base10(1);
        @media (min-width:1320px) {
          top: -60px;
          left: 190px;
        }
      }
    }
  }

  &.-i03 {
    width: 53.7%;
    max-width: 537px;
  }

  &.-i04 {
    & img {
      display: block;
      width: 100%;
//      max-width: 576px;
      margin: 0 auto;
    }
  }


}