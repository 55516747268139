@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Noto+Sans+JP:wght@400;500;700&family=Shippori+Mincho:wght@600&display=swap');

@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


// 源柔ゴシック
@font-face{ 
  font-family: 'genju-m';
  src: url('/assets/font/genju-m.eot'); /* IE9 Compat Modes */
  src: url('/assets/font/genju-m.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/genju-m.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/genju-m.ttf') format('truetype'); /* Safari, Android, iOS */
       //url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face{ 
  font-family: 'genju-b';
  src: url('/assets/font/genju-b.eot'); /* IE9 Compat Modes */
  src: url('/assets/font/genju-b.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/genju-b.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/genju-b.ttf') format('truetype'); /* Safari, Android, iOS */
       //url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}
@mixin font-gj($status) {
  font-weight: normal;
  font-style: normal;
  @if $status == 'm' {
    font-family: "genju-m",sans-serif;
  } @else if $status == 'b' {
    font-family: "genju-b",sans-serif;
  }
}


//源の角ゴシック | noto sans
@mixin font-ntsan($status) {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'b' {
    font-weight: 700;
  }
}

//源の明朝 | noto serif
/*
@mixin font-ntsel($status) {
  font-family: 'Noto Serif JP', serif;
  font-style: normal;
  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'b' {
    font-weight: 700;
  }
}
*/

//しっぽり明朝
@mixin font-shipporimin($status) {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 600;
  @if $status == 'sb' {
    font-weight: 600;
  }
}

//Montserrat
@mixin font-montserrat($status) {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  @if $status == 'm' {
    font-weight: 500;
  }
}
