@mixin btnsize($pc,$sp) {
  width: $pc;
  @media (min-width:600px) {
    width: $sp;
  }
}

@mixin btn($status) {
  clear: both;
  text-align: center;
  &_inner {
    width: 100%;
    padding: 15px 0;
    display: block;
    color: color-wh(1);
    line-height: 1.2;
    cursor: pointer;
    border-radius: 42px;
    @include font-ntsan("m");
    @extend %hover;
    @media (min-width:600px) {
      padding: 20px 0;
    }
  }
  @if $status == 1 {
    &_inner {
      background: color-effect02(1);
      font-size: 2.56rem;
      @media all and (min-width:600px) {
        font-size: 3.2rem;
      }
    }
  }
  @else if $status == 2 {
    &_inner {
      padding: 20px 0;
      background: color-effect02(1);
      font-size: 2.1rem;
      @media (min-width:600px) {
        padding: 23px 0;
        font-size: 3.2rem;
      }
    }
  }
  @else if $status == 3 {
    &_inner {
      background: color-effect02(1);
      font-size: 1.6rem;
      @media (min-width:600px) {
        font-size: 2rem;
      }
    }
  }
  @else if $status == 4 {
    &_inner {
      background: color-base09(1);
      font-size: 1.44rem;
      @media (min-width:600px) {
        font-size: 1.8rem;
      }
    }
  }
}


.p0-btn1 {
  @include btnsize(100%, 552px);
  max-width: 552px;
  @include btn(1);
  @media (max-width:600px) {
    max-width: 280px;
  }
  @media (min-width:600px) and (max-width:660px) {
    width: 100%;
  }
  &.-type01 {
    @media (min-width:600px) and (max-width:956px) {
      width: 100%;
    }
  }
}

.p0-btn2 {
  width: 100%;
  max-width: 640px;
  position: relative;
  @include btn(2);
  @media (min-width:760px) {
    width: 640px;
  }
}

.p0-btn3 {
  @include btnsize(100%, 350px);
  max-width: 350px;
  @include btn(3);
  @media (max-width:600px) {
    max-width: 280px;
  }
  &.-back {
    & .p0-btn3_inner {
      background: color-base03(1);
    }
  }
}


.p0-btn4 {
  @include btnsize(100%, 240px);
  max-width: 240px;
  @include btn(4);
  @media (max-width:600px) {
    max-width: 240px;
  }
}