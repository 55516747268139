.p1-drawer {
  &-hidden {
    display: none;
  }
  &-open {/* ハンバーガーアイコンの設置スペース */
    display: flex;
    height: $drawer-space;
    width: $drawer-space;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 1200;/* 重なり順を一番上に */
    cursor: pointer;
    @media all and (min-width:800px) {
      display: none;
    }
    /* ハンバーガーメニューのアイコン */
    & span, & span:before, & span:after {
      content: '';
      display: block;
      height: 3px;
      width: 25px;
      border-radius: 3px;
      background: #333;
      transition: 0.5s;
      position: absolute;
    }
    & span {
      &::before { bottom: 8px; }
      &::after { top: 8px; }
    }
  }
}

#drawer-check:checked {
  & ~ .p1-drawer-open span {/* アイコンがクリックされたら真ん中の線を透明に */
    background: rgba(255, 255, 255, 0);
    /* アイコンがクリックされたらアイコンが×印になように上下の線を回転 */
    &::before {
      bottom: 0;
      transform: rotate(45deg);
    }
    &::after {
      top: 0;
      transform: rotate(-45deg);
    }
  }
  & ~ .p1-gnav_content {
    top: 0; /* アイコンがクリックされたらメニューを表示 */
  }
}